import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Box, Typography, CircularProgress } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell } from 'recharts';

const Analytics = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      const data = {}; // Initialize an object to hold your data
      const projectsCollection = collection(db, 'projects');
      const projectsSnapshot = await getDocs(projectsCollection);
      const projectsData = projectsSnapshot.docs.map(doc => doc.data());

      // Process data to extract analytics
      // For example, calculate total revenue, tasks per status, etc.
      data.totalRevenue = projectsData.reduce((sum, project) => sum + (parseFloat(project.amountIncVat) || 0), 0);
      data.projectsByStatus = projectsData.reduce((acc, project) => {
        acc[project.projectStatus] = (acc[project.projectStatus] || 0) + 1;
        return acc;
      }, {});

      setAnalyticsData(data);
      setLoading(false);
    };

    fetchAnalyticsData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  // Prepare data for charts
  const projectStatusData = Object.keys(analyticsData.projectsByStatus).map(status => ({
    name: status,
    value: analyticsData.projectsByStatus[status],
  }));

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Typography variant="h4" gutterBottom>
         Analytics
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', mb: 4 }}>
        {/* Total Revenue Chart */}
        <Box sx={{ width: '45%' }}>
          <Typography variant="h6">Total Revenue</Typography>
          <LineChart width={500} height={300} data={[{name: 'Total Revenue', value: analyticsData.totalRevenue}]}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#8884d8" />
          </LineChart>
        </Box>
        
        {/* Project Status Distribution */}
        <Box sx={{ width: '45%' }}>
          <Typography variant="h6">Projects by Status</Typography>
          <PieChart width={400} height={400}>
            <Pie data={projectStatusData} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value">
              {projectStatusData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={['#0088FE', '#00C49F', '#FFBB28', '#FF8042'][index % 4]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </Box>
      </Box>
      
      {/* Additional charts can be added here */}
    </Box>
  );
};

export default Analytics;
