// src/components/Layout.js
import React from 'react';
import { CssBaseline, Box, Container } from '@mui/material';
import Sidebar from './Sidebar';

const Layout = ({ children, user }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar user={user} />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
