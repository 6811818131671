// src/components/EditClient.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid } from '@mui/material';
import { db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const EditClient = ({ clientId, onClose }) => {
  const [client, setClient] = useState({
    clientName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    stateCounty: '',
    country: '',
    postCode: '',
    telephoneNumber: '',
    email: '',
    pointOfContacts: [''], // Initialize with one empty POC
    companyReference: '',
    otherInfo: ''
  });

  useEffect(() => {
    if (clientId) {
      fetchClient(clientId);
    }
  }, [clientId]);

  const fetchClient = async (clientId) => {
    const clientDoc = await getDoc(doc(db, 'clients', clientId));
    if (clientDoc.exists()) {
      const clientData = clientDoc.data();
      const formattedClient = {
        ...clientData,
        pointOfContacts: Array.isArray(clientData.pointOfContacts)
          ? clientData.pointOfContacts
          : [clientData.pointOfContact || '']
      };
      setClient(formattedClient);
    } else {
      console.log("No such document!");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });
  };

  const handlePOCChange = (index, e) => {
    const { value } = e.target;
    const updatedPOCs = client.pointOfContacts.map((poc, i) => (
      i === index ? value : poc
    ));
    setClient({ ...client, pointOfContacts: updatedPOCs });
  };

  const addPOCField = () => {
    setClient({ ...client, pointOfContacts: [...client.pointOfContacts, ''] });
  };

  const removePOCField = (index) => {
    const updatedPOCs = client.pointOfContacts.filter((_, i) => i !== index);
    setClient({ ...client, pointOfContacts: updatedPOCs });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(db, 'clients', clientId), {
        clientName: client.clientName,
        addressLine1: client.addressLine1,
        addressLine2: client.addressLine2,
        addressLine3: client.addressLine3,
        city: client.city,
        stateCounty: client.stateCounty,
        country: client.country,
        postCode: client.postCode,
        telephoneNumber: client.telephoneNumber,
        email: client.email,
        pointOfContacts: client.pointOfContacts,
        companyReference: client.companyReference,
        otherInfo: client.otherInfo,
        createdAt: client.createdAt // Preserve the original creation date
      });
      onClose(); // Close the dialog on successful save
    } catch (error) {
      console.error('Error updating client: ', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h6">Edit Client</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Client Name"
            name="clientName"
            value={client.clientName}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 1"
            name="addressLine1"
            value={client.addressLine1}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 2"
            name="addressLine2"
            value={client.addressLine2}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address Line 3"
            name="addressLine3"
            value={client.addressLine3}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="City"
            name="city"
            value={client.city}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="State/County"
            name="stateCounty"
            value={client.stateCounty}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Country"
            name="country"
            value={client.country}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Post Code"
            name="postCode"
            value={client.postCode}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Telephone Number"
            name="telephoneNumber"
            value={client.telephoneNumber}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Email"
            name="email"
            value={client.email}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Points of Contact</Typography>
          {client.pointOfContacts.map((poc, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={10}>
                <TextField
                  label={`Point of Contact ${index + 1}`}
                  name={`pointOfContact${index}`}
                  value={poc}
                  onChange={(e) => handlePOCChange(index, e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => removePOCField(index)}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}
          <Button variant="contained" color="primary" onClick={addPOCField}>
            Add Another Point of Contact
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Company Reference"
            name="companyReference"
            value={client.companyReference}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Other Info"
            name="otherInfo"
            value={client.otherInfo}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditClient;

