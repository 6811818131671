// src/App.js
import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './App.css';
import Layout from './components/Layout';
import Orders from './components/Orders';
import Login from './components/Login';
import ProjectDetail from './components/ProjectDetail';
import Tasks from './components/Tasks';
import Clients from './components/Clients';
import Analytics from './components/Analytics';
import { CircularProgress, Box } from '@mui/material';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        if (window.location.pathname === '/login') {
          if (user.email === 'mustafa@fortcorps.com' || user.email === 'aseel@fortcorps.com' || user.email === 'muhab@fortcorps.com') {
            navigate('/tasks');  // Redirect to tasks page for this specific email
          } else {
            navigate('/');  // Redirect to the default main page
          }
        }

      } else {
        setUser(null);
        navigate('/login');
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [navigate]);
  

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      {user ? (
        <Layout user={user}>
          <Routes>
          <Route path="/" element={<Orders user={user} />} />
            <Route path="/project/:id" element={<ProjectDetail user={user} />} />
            <Route path="/tasks" element={<Tasks user={user}/>} />
            <Route path="/clients" element={<Clients user={user}/>} />
            <Route path="/analytics" element={<Analytics user={user}/>} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
