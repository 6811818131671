// src/components/AddProject.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs, query, orderBy, limit, where } from 'firebase/firestore';
import { Autocomplete } from '@mui/material';

const AddProject = ({ onClose }) => {
  const taskOptions = ['DSS', 'PS', 'VS', 'IMEQ', 'BEQ', 'FL', 'SS', 'EQ', 'LC', 'MA', 'RPS','SV', 'VES'];
  const countryOptions = ['Algeria', 'Bahrain', 'Djibouti', 'Egypt', 'Iran', 'Iraq', 'Jordan', 'Kuwait', 'Lebanon', 'Libya', 'Mauritania', 'Morocco', 'Oman', 'Pakistan', 'Palestine', 'Qatar', 'Saudi Arabia', 'Somalia', 'Sudan', 'Syria', 'Tunisia', 'Turkey', 'UAE', 'UAE - Abu Dhabi', 'UAE - Ajman', 'UAE - Dubai', 'UAE - Fujairah', 'UAE - RAK', 'UAE - Sharjah', 'UAE - UAQ', 'Yemen'];
  const projectManagerOptions = ['Zak', 'Omar', 'Aseel', 'Muhab', 'Mustafa'];

  const [project, setProject] = useState({
    projectRef: '',
    projectBriefing: '',
    invoiceNo: '',
    projectStatus: 'In Progress',
    projectManager: '',
    cost: '',
    client: '',
    poc: '',
    dateForInvoice: '',
    invoiceRaised: 'No',
    paymentStatus: '',
    paidOn: '',
    paymentNotes: '',
    tasks: [{ taskNo: 1, subject: '', country: '', task: '', orderAccepted: '', dueDate: '', taskStatus: '', analyst1: '', analyst2: '', income: '', vat: '0', amountIncVat: '', completedOn: '' }]
  });

  const [submitDisabled, setSubmitDisabled] = useState(false); // Initialize submitDisabled state
  const [clientOptions, setClientOptions] = useState([]);
  const [manualProjectNumber, setManualProjectNumber] = useState('');
  const [isManualEntry, setIsManualEntry] = useState(false);

  useEffect(() => {
    const generateProjectAndInvoiceNumbers = async () => {
      if (isManualEntry && manualProjectNumber) {
        const year = new Date().getFullYear();
        setProject((prevState) => ({
          ...prevState,
          projectRef: `P${manualProjectNumber}-${year}`,
          invoiceNo: `INV${manualProjectNumber}${year}`
        }));
      } else {
        const q = query(collection(db, 'projects'), orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);
        
        let highestProjectNumber = 292; // Start from 292 if no projects are found
        querySnapshot.forEach((doc) => {
          const projectRef = doc.data().projectRef;
          const projectNumber = parseInt(projectRef.split('-')[0].substring(1));
          if (projectNumber > highestProjectNumber) {
            highestProjectNumber = projectNumber;
          }
        });
        
        const nextProjectNumber = highestProjectNumber + 1;
        const year = new Date().getFullYear();
    
        setProject((prevState) => ({
          ...prevState,
          projectRef: `P${nextProjectNumber}-${year}`,
          invoiceNo: `INV${nextProjectNumber}${year}`
        }));
      }
    };
    

    generateProjectAndInvoiceNumbers();

    const fetchClients = async () => {
      const clientsCollection = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCollection);
      const clientsData = clientsSnapshot.docs.map(doc => doc.data().clientName);
      setClientOptions(clientsData);
    };

    fetchClients();
  }, [isManualEntry, manualProjectNumber]);

  const fetchPointsOfContact = async (clientName) => {
    const clientsCollection = collection(db, 'clients');
    const clientQuery = query(clientsCollection, where('clientName', '==', clientName));
    const clientSnapshot = await getDocs(clientQuery);
    if (!clientSnapshot.empty) {
      const clientData = clientSnapshot.docs[0].data();
      return clientData.pointOfContacts || [];
    }
    return [];
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProject({ ...project, [name]: value });
  };

  const handleTaskChange = (index, e) => {
    const { name, value } = e.target;
    const newTasks = [...project.tasks];
    newTasks[index][name] = value;

    if (name === 'income' || name === 'vat') {
      const vatValue = name === 'vat' ? value : newTasks[index].vat;
      const incomeValue = name === 'income' ? value : newTasks[index].income;
      const amountIncVat = parseFloat(incomeValue) + parseFloat(incomeValue) * parseFloat(vatValue) / 100;
      newTasks[index].amountIncVat = amountIncVat.toFixed(2);
    }

    setProject((prevState) => ({ ...prevState, tasks: newTasks }));

    const updatedIncome = newTasks.reduce((acc, task) => acc + parseFloat(task.income || 0), 0);
    const updatedVat = newTasks.length > 0 ? newTasks[0].vat : '0';
    const updatedAmountIncVat = newTasks.reduce((acc, task) => acc + parseFloat(task.amountIncVat || 0), 0);

    setProject((prevState) => ({
      ...prevState,
      income: updatedIncome.toFixed(2),
      vat: updatedVat,
      amountIncVat: updatedAmountIncVat.toFixed(2)
    }));
  };

  const handleAddTask = () => {
    const newTaskNo = project.tasks.length + 1;
    setProject({ ...project, tasks: [...project.tasks, { taskNo: newTaskNo, subject: '', country: '', task: '', orderAccepted: '', dueDate: '', taskStatus: '', analyst1: '', analyst2: '', income: '', vat: '0', amountIncVat: '',
    completedOn: '' }] });
  };

  const handleRemoveTask = (index) => {
    const newTasks = project.tasks.filter((_, i) => i !== index);
    setProject({ ...project, tasks: newTasks });

    const updatedIncome = newTasks.reduce((acc, task) => acc + parseFloat(task.income || 0), 0);
    const updatedVat = newTasks.length > 0 ? newTasks[0].vat : '0';
    const updatedAmountIncVat = newTasks.reduce((acc, task) => acc + parseFloat(task.amountIncVat || 0), 0);

    setProject((prevState) => ({
      ...prevState,
      income: updatedIncome.toFixed(2),
      vat: updatedVat,
      amountIncVat: updatedAmountIncVat.toFixed(2)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitDisabled) return; // Prevent multiple submissions
  
    try {
      setSubmitDisabled(true); // Disable further submissions
      await addDoc(collection(db, 'projects'), {
        ...project,
        dateForInvoice: project.dateForInvoice || 'N/A',
        paidOn: project.paidOn || 'N/A',
        createdAt: new Date(),
        projectStatus: 'In Progress'
      });
      onClose();
    } catch (error) {
      console.error('Error adding document: ', error);
    } finally {
      setSubmitDisabled(false); // Re-enable submissions after completion
    }
  };
  

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, position: 'relative', pb: 7 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Project Ref"
            name="projectRef"
            value={project.projectRef}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Invoice No"
            name="invoiceNo"
            value={project.invoiceNo}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={<Checkbox checked={isManualEntry} onChange={(e) => setIsManualEntry(e.target.checked)} />}
            label="Enter project number manually"
          />
        </Grid>
        {isManualEntry && (
          <Grid item xs={12} sm={6}>
            <TextField
              label="Manual Project Number"
              name="manualProjectNumber"
              value={manualProjectNumber}
              onChange={(e) => setManualProjectNumber(e.target.value)}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            label="Project Briefing"
            name="projectBriefing"
            value={project.projectBriefing}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={projectManagerOptions}
            value={project.projectManager}
            onChange={(event, newValue) => {
              setProject({ ...project, projectManager: newValue });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Project Manager"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Cost"
            name="cost"
            value={project.cost}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            freeSolo
            options={clientOptions}
            value={project.client}
            onInputChange={async (event, newValue) => {
              setProject({ ...project, client: newValue });
              if (newValue) {
                const pocs = await fetchPointsOfContact(newValue);
                setProject((prevState) => ({
                  ...prevState,
                  pocs: pocs,
                  poc: pocs.length > 0 ? pocs[0] : '', // Select the first POC by default or leave empty
                }));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client"
                name="client"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            freeSolo
            options={project.pocs || []}
            value={project.poc}
            onInputChange={(event, newValue) => {
              setProject({ ...project, poc: newValue });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Point of Contact"
                name="poc"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date for Invoice"
            name="dateForInvoice"
            value={project.dateForInvoice}
            onChange={handleInputChange}
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Invoice Raised</InputLabel>
            <Select
              name="invoiceRaised"
              value={project.invoiceRaised}
              onChange={handleInputChange}
              fullWidth
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Payment Status"
            name="paymentStatus"
            value={project.paymentStatus}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Paid on"
            name="paidOn"
            value={project.paidOn}
            onChange={handleInputChange}
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Payment Notes"
            name="paymentNotes"
            value={project.paymentNotes}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Tasks Required</Typography>
          {project.tasks.map((task, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Task No"
                    name="taskNo"
                    value={task.taskNo}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Subject"
                    name="subject"
                    value={task.subject}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={countryOptions}
                    value={task.country}
                    onChange={(event, newValue) => {
                      const newTasks = [...project.tasks];
                      newTasks[index].country = newValue;
                      setProject((prevState) => ({ ...prevState, tasks: newTasks }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={taskOptions}
                    value={task.task}
                    onChange={(event, newValue) => {
                      const newTasks = [...project.tasks];
                      newTasks[index].task = newValue;
                      setProject((prevState) => ({ ...prevState, tasks: newTasks }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Task"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Order Accepted"
                    name="orderAccepted"
                    value={task.orderAccepted}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Due Date"
                    name="dueDate"
                    value={task.dueDate}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={projectManagerOptions}
                    value={task.analyst1}
                    onChange={(event, newValue) => {
                      const newTasks = [...project.tasks];
                      newTasks[index].analyst1 = newValue;
                      setProject((prevState) => ({ ...prevState, tasks: newTasks }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Analyst 1"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    options={projectManagerOptions}
                    value={task.analyst2}
                    onChange={(event, newValue) => {
                      const newTasks = [...project.tasks];
                      newTasks[index].analyst2 = newValue;
                      setProject((prevState) => ({ ...prevState, tasks: newTasks }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Analyst 2"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Fee"
                    name="income"
                    value={task.income}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>VAT</InputLabel>
                    <Select
                      name="vat"
                      value={task.vat}
                      onChange={(e) => handleTaskChange(index, e)}
                      fullWidth
                    >
                      <MenuItem value={0}>0%</MenuItem>
                      <MenuItem value={20}>20%</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Fee Inc VAT"
                    name="amountIncVat"
                    value={task.amountIncVat}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Completed On"
                    name="completedOn"
                    value={task.completedOn}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Button variant="outlined" color="secondary" onClick={() => handleRemoveTask(index)} sx={{ mt: 2 }}>
                Remove Task
              </Button>
            </Box>
          ))}
          <Button variant="outlined" onClick={handleAddTask}>
            Add Task
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              position: 'absolute',
              bottom: 8,
              left: 16,
            }}
            disabled={submitDisabled} // Disable button while submitting
          >
            Save Project
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddProject;
