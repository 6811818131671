// src/components/Orders.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, getDocs, updateDoc, doc, getDoc, query, where, Timestamp } from 'firebase/firestore'; 


import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, Box, TextField, InputAdornment, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, Collapse,
  FormControlLabel,FormControl,InputLabel
} from '@mui/material';
import { Search, Clear, ExpandMore, ExpandLess, FileDownload, FilterList } from '@mui/icons-material';
import { Radio, RadioGroup } from '@mui/material';

import AddProject from './AddProject';
import AddClient from './AddClient';
import EditProject from './EditProject';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { Select, MenuItem } from '@mui/material';
import { Sort } from '@mui/icons-material';





const Orders = ({ user }) => {
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState('');
  const [openAdd, setOpenAdd] = useState(false);
  const [openAddClient, setOpenAddClient] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [editableCell, setEditableCell] = useState(null);
  const [editableTaskCell, setEditableTaskCell] = useState(null);

  const taskOptions = ['DSS', 'PS', 'VS', 'IMEQ', 'BEQ', 'FL', 'SS', 'EQ','LC','MA','RPS', 'SV', 'VES'];


  const navigate = useNavigate();

  const [isInvoiceDialogOpen, setIsInvoiceDialogOpen] = useState(false);
const [invoiceChoice, setInvoiceChoice] = useState('Show subject');

const [isSortDialogOpen, setIsSortDialogOpen] = useState(false);
const [sortColumn, setSortColumn] = useState('projectRef');
const [sortOrder, setSortOrder] = useState('desc');

const [selectedCompany, setSelectedCompany] = useState('UK'); // Default to UK company


  // Redirect if the user's email is mustafa@fortcorps.com
  useEffect(() => {
    if (user.email === 'mustafa@fortcorps.com' || user.email === 'aseel@fortcorps.com' || user.email === 'muhab@fortcorps.com') {
      navigate('/tasks');  // Redirect to tasks page if the user is not allowed to access Orders
    }
  }, [user, navigate]);


  // Load selected statuses from local storage when component mounts
  useEffect(() => {
    const savedStatuses = localStorage.getItem('selectedStatuses');
    if (savedStatuses) {
      setSelectedStatuses(JSON.parse(savedStatuses));
    }
  }, []);


  const handleStatusChange = (event) => {
    const updatedStatuses = {
      ...selectedStatuses,
      [event.target.name]: event.target.checked
    };
    setSelectedStatuses(updatedStatuses);
    localStorage.setItem('selectedStatuses', JSON.stringify(updatedStatuses));
  };

  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState({
    'In Progress': true,
    'Completed': true,
    'Overdue': true,
    'Attention': true,
    'To be invoiced': true,
    'Invoiced': true
  });
  
  
  // We are doing client side filtering here. Things may get slow in the future as collection records grow. 
  // Monitor and check if we have to switch to server side filtering
  const fetchProjects = async (searchQuery = '') => {
    const projectsCollection = collection(db, 'projects');
    const projectsSnapshot = await getDocs(projectsCollection);
    let projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Filter out empty projects
        projectsData = projectsData.filter(project => project.projectRef && project.projectRef.trim() !== '');


  
    let filteredProjectsData = projectsData;
  
    filteredProjectsData = projectsData.filter(project => 
      (project.projectRef && project.projectRef.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (project.projectManager && project.projectManager.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (project.client && project.client.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (project.projectStatus && project.projectStatus.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (project.projectBriefing && project.projectBriefing.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (project.poc && project.poc.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (project.tasks && project.tasks.some(task => task.subject && task.subject.toLowerCase().includes(searchQuery.toLowerCase())))
    );
    
    
    filteredProjectsData = filteredProjectsData.filter(project => selectedStatuses[project.projectStatus]);


    // Add this sorting logic
    if (sortColumn) {
      filteredProjectsData.sort((a, b) => {
        let aValue, bValue;
  
        if (sortColumn === 'projectRef') {
          const aRef = a.projectRef || ''; // Fallback to an empty string if projectRef is undefined
          const bRef = b.projectRef || ''; // Fallback to an empty string if projectRef is undefined
          const [aX, aYear] = aRef.split('-').map(part => part.replace('P', ''));
          const [bX, bYear] = bRef.split('-').map(part => part.replace('P', ''));
                  const aXNum = parseInt(aX, 10);
          const bXNum = parseInt(bX, 10);
          if (aYear === bYear) {
            aValue = aXNum;
            bValue = bXNum;
          } else {
            aValue = parseInt(aYear, 10);
            bValue = parseInt(bYear, 10);
          }
        } else if (sortColumn.startsWith('tasks')) {
          const taskField = sortColumn.split('.')[1];
          aValue = a.tasks[0] ? a.tasks[0][taskField] : '';
          bValue = b.tasks[0] ? b.tasks[0][taskField] : '';
        } else if (sortColumn === 'amountIncVat') {
          aValue = parseFloat(a.amountIncVat);
          bValue = parseFloat(b.amountIncVat);
        } else {
          aValue = a[sortColumn];
          bValue = b[sortColumn];
        }
  
        if (aValue === undefined) aValue = '';
        if (bValue === undefined) bValue = '';
  
        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    }
  
  
  
      
  
    const now = new Date();
    const updatedProjects = filteredProjectsData.map(async project => {
        let newProjectStatus = 'In Progress';
        let isOverdueProject = false;
        let isAttentionProject = false;
        let allTasksCompleted = true;
        let lastCompletedDate = null;
    
       // Separate logic to handle task status updates independently of project status
if (project.tasks && project.tasks.length > 0) {
  const updatedTasks = project.tasks.map(async task => {
      let newTaskStatus = 'In Progress';
      const dueDate = new Date(task.dueDate);
      const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const startOfTomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);

      const dueDateOnly = new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate());
      const startOfTodayOnly = new Date(startOfToday.getFullYear(), startOfToday.getMonth(), startOfToday.getDate());
      const startOfTomorrowOnly = new Date(startOfTomorrow.getFullYear(), startOfTomorrow.getMonth(), startOfTomorrow.getDate());

      console.log("dueDateOnly:", dueDateOnly, "startOfTodayOnly:", startOfTodayOnly, "startOfTomorrowOnly:", startOfTomorrowOnly);



      if (task.completedOn) {
          newTaskStatus = 'Completed';
          const completedDate = new Date(task.completedOn);
          if (!lastCompletedDate || completedDate > lastCompletedDate) {
              lastCompletedDate = completedDate;
          }
      } else {
          allTasksCompleted = false;
          if (dueDate < startOfToday) {
              newTaskStatus = 'Overdue';
              isOverdueProject = true;
          } else if (dueDateOnly >= startOfTodayOnly && dueDateOnly <= startOfTomorrowOnly) {
            newTaskStatus = 'Attention';
            isAttentionProject = true;
        }
        
      }

      if (task.taskStatus !== newTaskStatus) {
          const projectRef = doc(db, 'projects', project.id);
          const taskIndex = project.tasks.indexOf(task);
          const updatedTasks = [...project.tasks];
          updatedTasks[taskIndex] = { ...task, taskStatus: newTaskStatus };
          await updateDoc(projectRef, { tasks: updatedTasks });
      }

      return { ...task, taskStatus: newTaskStatus };
  });

  project.tasks = await Promise.all(updatedTasks);
}

// Check project status only if invoice is not raised
if (project.invoiceRaised === 'Yes') {
  newProjectStatus = 'Invoiced';
} else {
  if (allTasksCompleted) {
      const daysSinceLastCompleted = (now - lastCompletedDate) / (1000 * 60 * 60 * 24);
      if (daysSinceLastCompleted >= 10) {
          newProjectStatus = 'To be invoiced';
      } else {
          newProjectStatus = 'Completed';
      }
  } else if (isOverdueProject) {
      newProjectStatus = 'Overdue';
  } else if (isAttentionProject) {
      newProjectStatus = 'Attention';
  }
}

// Update project status only if it has changed
if (project.projectStatus !== newProjectStatus) {
  const projectRef = doc(db, 'projects', project.id);
  await updateDoc(projectRef, { projectStatus: newProjectStatus });
}

    
        return { ...project, projectStatus: newProjectStatus };
    });
    
    setProjects(await Promise.all(updatedProjects));
    
  };
  
  
  

  useEffect(() => {
    fetchProjects();
  }, [selectedStatuses, sortColumn, sortOrder]); // Add sortColumn and sortOrder as dependencies
  

  

  const handleSearchChange = (event) => {
    const newSearch = event.target.value;
    setSearch(newSearch);
    fetchProjects(newSearch);
  };
  
  const clearSearch = () => {
    setSearch('');
    fetchProjects();
  };
  

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenAddClient = () => {
    setOpenAddClient(true);
  };

  const handleCloseAddClient = () => {
    setOpenAddClient(false);
  };

  const handleOpenEdit = () => {
    const selected = selectedProjects[0];
    setSelectedProjectId(selected);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleRowClick = (id) => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleProjectRefClick = (id) => {
    navigate(`/project/${id}`);
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedProjects([...selectedProjects, id]);
    } else {
      setSelectedProjects(selectedProjects.filter(pid => pid !== id));
    }

  };


// Function to convert Firestore Timestamp to a JavaScript Date object
const convertTimestampToDate = (timestamp) => {
  if (timestamp instanceof Timestamp) {
    return timestamp.toDate();
  }
  if (timestamp instanceof Date) {
    return timestamp;
  }
  if (typeof timestamp === 'string') {
    return new Date(timestamp);
  }
  return null;
};




const handleCellClick = (projectId, field, taskIndex = null) => {
  if (taskIndex === null) {
    setEditableCell({ projectId, field });
  } else {
    setEditableTaskCell({ projectId, taskIndex, field });
  }
};

const handleInputChange = (event, projectId, field, taskIndex = null) => {
  const value = event.target?.value || null;
  setProjects(prevProjects => {
    const updatedProjects = prevProjects.map(project => {
      if (project.id !== projectId) return project;

      if (taskIndex === null) {
        return { ...project, [field]: value };
      } else {
        const updatedTasks = project.tasks.map((task, index) => {
          if (index !== taskIndex) return task;

          const updatedTask = { ...task, [field]: value };

          // Recalculate amountIncVat for the task
          if (field === 'income' || field === 'vat') {
            const income = field === 'income' ? parseFloat(value) : parseFloat(task.income);
            const vat = field === 'vat' ? parseFloat(value) : parseFloat(task.vat);
            const amountIncVat = income + (income * vat / 100);
            updatedTask.amountIncVat = amountIncVat.toFixed(2);
          }

          return updatedTask;
        });

        // Recalculate project-level fields
        const updatedIncome = updatedTasks.reduce((acc, task) => acc + parseFloat(task.income || 0), 0);
        const updatedVat = updatedTasks.length > 0 ? updatedTasks[0].vat : '0';
        const updatedAmountIncVat = updatedTasks.reduce((acc, task) => acc + parseFloat(task.amountIncVat || 0), 0);

        return {
          ...project,
          tasks: updatedTasks,
          income: updatedIncome.toFixed(2),
          vat: updatedVat,
          amountIncVat: updatedAmountIncVat.toFixed(2)
        };
      }
    });

    return updatedProjects;
  });
};



const handleBlur = async (projectId, field, taskIndex = null) => {
  const project = projects.find(p => p.id === projectId);
  if (taskIndex === null) {
    await updateDoc(doc(db, 'projects', projectId), { [field]: project[field] || null });
    setEditableCell(null);
  } else {
    await updateDoc(doc(db, 'projects', projectId), { tasks: project.tasks, income: project.income, vat: project.vat, amountIncVat: project.amountIncVat });
    setEditableTaskCell(null);
  }
};




const handleCreateInvoice = () => {
  if (selectedProjects.length === 1) {
    setIsInvoiceDialogOpen(true);
  }
};

  


 const handleInvoiceCreation = async () => {
  const projectId = selectedProjects[0];
  const projectDoc = await getDoc(doc(db, 'projects', projectId));

  if (projectDoc.exists()) {
    const projectData = projectDoc.data();

    // Fetch the client details from the clients collection
    const clientsCollection = collection(db, 'clients');
    const clientQuery = query(clientsCollection, where('clientName', '==', projectData.client));
    const clientSnapshot = await getDocs(clientQuery);
    let clientData = {};

    if (!clientSnapshot.empty) {
      clientData = clientSnapshot.docs[0].data();
    } else {
      console.error('No matching client document!');
    }


    // Construct the client address
    const addressFields = [
      clientData.addressLine1,
      clientData.addressLine2,
      clientData.addressLine3,
      clientData.city,
      clientData.postCode,
      clientData.country,
    ];
    const clientAddress = addressFields.filter(field => field).join('<br>');


        // Format the date
        const rawDate = projectData.dateForInvoice;
        const dateObject = new Date(rawDate);
        const formattedDate = dateObject.toLocaleDateString('en-GB'); // This will format the date as DD/MM/YYYY
    

    // Calculate the VAT amount
    const vatRate = projectData.vat; // Assume this is provided as a percentage, e.g., 20 for 20%
    const totalAmount = projectData.income; // Assuming this is the pre-VAT amount
    const vatAmount = totalAmount * (vatRate / 100);

    // Format the VAT label and amount
    const vatLabel = `VAT @ ${vatRate}%`;
    const formattedVatAmount = `${formatMoney(vatAmount)}`;



    // Fetch the invoice template from the public folder
    const invoiceTemplateResponse = await fetch('/invoiceTemplate.html?v=1.6');
    let invoiceTemplate = await invoiceTemplateResponse.text();

    // Fetch conversion rate from GBP to AED
    const conversionRate = await fetchConversionRate('GBP', 'AED');


        // Define company details based on the selected company
        const companyDetails = {};

        if (selectedCompany === 'UK') {
          companyDetails.name = 'Fort Corps Limited';
          companyDetails.addressLines = ['Clarendon House', '2nd Floor 52 Cornmarket St', 'Oxford, England, OX1 3HJ'];
          companyDetails.phone = '+44 186 523 8285';
          companyDetails.email = 'info@fortcorps.com';
          companyDetails.accountHolder = 'Fort Corps Limited';
          companyDetails.bankDetailsLines = ['Barclays Bank, 54 Cornmarket Street, Oxford, OX1 3HB'];
          companyDetails.sortCode = '20-65-20';
          companyDetails.accountNo = '23954005';
          companyDetails.iban = 'GB56BUKB20652023954005';
          companyDetails.swift = 'BUKBGB22';
          companyDetails.termsOfPayment = '30 days';
          companyDetails.footerDetails = 'Fort Corps Limited | Company Number 13497195 | VAT Registration Number 404510152 | Clarendon House, 2nd Floor 52 Cornmarket St, Oxford, England, OX1 3HJ';
          companyDetails.logo = 'invoice_logo.png'; // Set the UK logo image file
        } else if (selectedCompany === 'UAE') {
          companyDetails.name = 'Fort Core LLC FZ';
          companyDetails.addressLines = ['Business Center 1, M Floor', 'The Meydan Hotel, Nad Al', 'Sheba, Dubai, United Arab Emirates'];
          companyDetails.phone = '+971 4 368 7580';
          companyDetails.email = 'zak@fortcore.com';
          companyDetails.accountHolder = 'Fort Core LLC FZ';
          companyDetails.bankDetailsLines = ['Abu Dhabi Islamic Bank, Arenco Tower, Dubai Media City'];
          companyDetails.sortCode = 'N/A';
          companyDetails.accountNo = '19186681';
          companyDetails.iban = 'AE210500000000019186681';
          companyDetails.swift = 'ABDIAEADXXX';
          companyDetails.termsOfPayment = '30 days';
          companyDetails.footerDetails = 'Fort Core LLC FZ | VAT Reg Number 103000956680 | Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai, United Arab Emirates';
          companyDetails.logo = 'uae_logo.png'; // Set the UAE logo image file
        }
    


        // If UAE company is selected, show the Fee (AED) column and header
        if (selectedCompany === 'UAE') {
          const totalAed = (totalAmount * conversionRate).toFixed(2);
          const vatAedAmount = (vatAmount * conversionRate).toFixed(2);
          
          const totalIncVatAed = (parseFloat(totalAed) + parseFloat(vatAedAmount)).toFixed(2);
    
          // Update the template to show the AED columns for total, VAT, and total inc VAT
          invoiceTemplate = invoiceTemplate.replace('id="feeAEDHeader" style="display:none;"', 'id="feeAEDHeader" style="display:table-cell;"');
          invoiceTemplate = invoiceTemplate.replace('id="totalAED" style="display:none;"', 'id="totalAED" style="display:table-cell;"');
          invoiceTemplate = invoiceTemplate.replace('id="vatAEDAmount" style="display:none;"', 'id="vatAEDAmount" style="display:table-cell;"');
          invoiceTemplate = invoiceTemplate.replace('id="totalIncVatAED" style="display:none;"', 'id="totalIncVatAED" style="display:table-cell;"');
    
              // Apply the smaller font size for UAE
    invoiceTemplate = invoiceTemplate.replace('class="invoice-items"', 'class="invoice-items uae-font-small"');

          // Replace placeholders with AED values
          invoiceTemplate = invoiceTemplate
            .replace('[Total AED]', ` ${formatMoneyWithoutPoundSign(totalAed).replace('.00','')}`)
            .replace('[VAT AED]', ` ${formatMoneyWithoutPoundSign(vatAedAmount).replace('.00','')}`)
            .replace('[Total Inc. VAT AED]', `${formatMoneyWithoutPoundSign(totalIncVatAed).replace('.00', '')}`);
        }
    


    // Replace placeholders with actual data
    invoiceTemplate = invoiceTemplate
      .replace('[Client Name]', `<b>${clientData.clientName || ''}</b>`)
      .replace('[Client Address]', clientAddress)
      .replace('[Invoice Date]', formattedDate.replace(/\//g, '-'))  // Replace slashes with dashes
      .replace('[Invoice No]', projectData.invoiceNo)
      .replace('[Project No]', projectData.projectRef)
      .replace('[Project Ref]', projectData.paymentNotes)
      .replace('[Ordered by]', projectData.poc)
      .replace('[Total]', `${formatMoney(projectData.income).replace('.00', '')}`)
      .replace('VAT', vatLabel) // Replace the VAT label with VAT @ 20%, etc.
      .replace('[VAT Amount]', formattedVatAmount) // Replace the VAT amount
      .replace('[Total Inc. VAT]', `${formatMoney(projectData.amountIncVat).replace('.00', '')}`)
      .replace(/\[Company Name\]/g, companyDetails.name)
      .replace(/\[Company Address Line 1\]/g, companyDetails.addressLines[0] || '')
      .replace(/\[Company Address Line 2\]/g, companyDetails.addressLines[1] || '')
      .replace(/\[Company Address Line 3\]/g, companyDetails.addressLines[2] || '')
      .replace(/\[Company Address Line 4\]/g, companyDetails.addressLines[3] || '')
      .replace(/\[Company Phone\]/g, companyDetails.phone)
      .replace(/\[Company Email\]/g, companyDetails.email)
      .replace(/\[Account Holder\]/g, companyDetails.accountHolder)
      .replace(/\[Bank Details Line 1\]/g, companyDetails.bankDetailsLines[0] || '')
      .replace(/\[Bank Details Line 2\]/g, companyDetails.bankDetailsLines[1] || '')
      .replace(/\[Sort Code\]/g, companyDetails.sortCode)
      .replace(/\[Account No\]/g, companyDetails.accountNo)
      .replace(/\[IBAN\]/g, companyDetails.iban)
      .replace(/\[SWIFT\]/g, companyDetails.swift)
      .replace(/\[Terms of Payment\]/g, companyDetails.termsOfPayment)
      .replace(/\[Footer Company Details\]/g, companyDetails.footerDetails)
      .replace(/\[Company Logo\]/g, companyDetails.logo);

    

      


    // Task description mapping
    const taskDescriptionMap = {
      'DSS': 'Director-Shareholder Search',
      'PS': 'Property Ownership Enquiries',
      'VS': 'Vehicle Ownership Enquiries',
      'IMEQ': 'Immigration Enquiries',
      'BEQ': 'Banking Relationship Enquiries',
      'FL': 'Corporate Records Report',
      'SS': 'Subsidiary Search',
      'EQ': 'Human Source Enquiries',
      'LC': 'Litigation Enquiries',
      'MA': 'Media Archive Search',
      'RPS': 'Reverse Property Ownership Enquiries',
      'SV': 'Site Visit',
      'VES' : 'Vessle Ownership Enquiries'
    };


    // Populate the task details in the invoice
    let tasksHtml = '';
    projectData.tasks.forEach((task, index) => {
      const subject = invoiceChoice === 'Anonymise subject' ? 'Subject of Interest' : task.subject;
      const taskDescription = taskDescriptionMap[task.task] ? `${taskDescriptionMap[task.task]} (${task.country})` : `${task.task} (${task.country})`;
      const feeAED = (task.income * conversionRate).toFixed(2); // Convert GBP to AED

      tasksHtml += `
        <tr>
        <td>${index + 1}</td>
        <td>${subject}</td>
        <td>${taskDescription}</td>
        <td>${formatMoney(task.income)}</td>
        ${selectedCompany === 'UAE' ? `<td> ${formatMoneyWithoutPoundSign(feeAED).replace('.00', '')}</td>` : ''}
        </tr>
      `;
    });

    // Insert the tasksHtml into the invoiceItems tbody
    invoiceTemplate = invoiceTemplate.replace('<tbody id="invoiceItems">', `<tbody id="invoiceItems">${tasksHtml}`);

        // Calculate the number of rows and adjust the footer and payment details margin-top accordingly
        const rowCount = projectData.tasks.length;
        
        let footerMarginTop = 100; // default margin
        let paymentMarginTop = 100; // default margin

        if (rowCount >= 16) {
            footerMarginTop = 750;
            paymentMarginTop = 0;
        } else if (rowCount == 15) {
            footerMarginTop = 700;
            paymentMarginTop = 80;
        } else if (rowCount == 14) {
            footerMarginTop = 650;
            paymentMarginTop = 650;
        } else if (rowCount == 13) {
            footerMarginTop = 700;
            paymentMarginTop = 160;
        } else if (rowCount == 12) {
            footerMarginTop = 550;
            paymentMarginTop = 550;
        } else if (rowCount == 11) {
            footerMarginTop = 500;
            paymentMarginTop = 500;
        } else if (rowCount == 10) {
            footerMarginTop = 700;
            paymentMarginTop = 250;
        } else if (rowCount == 9) {
            footerMarginTop = 700;
            paymentMarginTop = 300;
        } else if (rowCount == 8) {
            footerMarginTop = 700;
            paymentMarginTop = 300;
        } else if (rowCount == 7) {
            footerMarginTop = 700;
            paymentMarginTop = 300;
        } else if (rowCount == 6) {
            footerMarginTop = 20;
            paymentMarginTop = 15;
        } else if (rowCount == 5) {
            footerMarginTop = 20;
            paymentMarginTop = 20;
        } else if (rowCount == 4) {
            footerMarginTop = 30;
            paymentMarginTop = 20;
        } else if (rowCount == 3) {
            footerMarginTop = 40;
            paymentMarginTop = 40;
        } else if (rowCount == 2) {
            footerMarginTop = 50;
            paymentMarginTop = 50;
        } else {
            footerMarginTop = 105;
            paymentMarginTop = 20; // Adjust as necessary for fewer rows
        }

        // Update the footer and payment details margin-top in the template
        invoiceTemplate = invoiceTemplate.replace('margin-top: 100px;', `margin-top: ${footerMarginTop}px;`);
        invoiceTemplate = invoiceTemplate.replace('[PAYMENT_MARGIN_TOP]', `${paymentMarginTop}px`);


    // Create a hidden div in memory to hold the invoice template
    const invoiceDiv = document.createElement('div');
    invoiceDiv.innerHTML = invoiceTemplate;

    // Generate PDF using html2pdf.js with higher quality settings
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `${projectData.invoiceNo}.pdf`,
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { 
        scale: 4, 
        useCORS: true, 
        allowTaint: true, 
        logging: true,
        dpi: 300,
        letterRendering: true
      },
      jsPDF: { 
        unit: 'in', 
        format: 'a4', 
        orientation: 'portrait'
      }
    };

    html2pdf().from(invoiceDiv).set(opt).save().then(() => {
      // No need to remove the div since it was never added to the DOM
    });
  } else {
    console.error('No such project document!');
  }
  setIsInvoiceDialogOpen(false);
};

// Function to fetch the conversion rate
const fetchConversionRate = async (fromCurrency, toCurrency) => {
  const response = await fetch(`https://api.exchangerate-api.com/v4/latest/${fromCurrency}`);
  const data = await response.json();
  return data.rates[toCurrency];
};




  
const exportToExcel = async () => {
  // Fetch all projects
  const projectsCollection = collection(db, 'projects');
  const projectsSnapshot = await getDocs(projectsCollection);
  let projectsData = projectsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

  // Filter projects to include only selected ones if any are selected
  if (selectedProjects.length > 0) {
    projectsData = projectsData.filter(project => selectedProjects.includes(project.id));
  }

  // Helper function to ensure numeric values
  const ensureNumber = (value) => {
    const num = Number(value);
    return isNaN(num) ? 0 : num;
  };

  // Prepare data for Excel
  const excelData = projectsData.flatMap(project => {
    if (!project.tasks || project.tasks.length === 0) {
      // Create a single row for projects without tasks
      return [{
        'Project Ref': project.projectRef,
        'Project Manager': project.projectManager,
        'Client': project.client,
        'Project Status': project.projectStatus,
        'Fees': ensureNumber(project.income),
        'VAT': ensureNumber(project.vat),
        'Amount Inc VAT': ensureNumber(project.amountIncVat),
        'Invoice Raised': project.invoiceRaised,
        'Invoice Date': project.dateForInvoice,
        'Paid On': project.paidOn,
        'Payment Status': project.paymentStatus,
        'Payment Notes': project.paymentNotes,
        'Cost': ensureNumber(project.cost),
        'PoC': project.poc,
        '# Tasks': 0,
        'Task No': '',
        'Subject': '',
        'Country': '',
        'Task': '',
        'Order Accepted': '',
        'Due Date': '',
        'Task Status': '',
        'Completed On': '',
        'Analyst 1': '',
        'Analyst 2': '',
        'Task Fees': 0,
        'Task VAT': 0,
        'Task Amount Inc VAT': 0
      }];
    } else {
      // Create a row for each task within the project
      return project.tasks.map(task => ({
        'Project Ref': project.projectRef,
        'Project Manager': project.projectManager,
        'Client': project.client,
        'Project Status': project.projectStatus,
        'Fees': ensureNumber(project.income),
        'VAT': ensureNumber(project.vat),
        'Amount Inc VAT': ensureNumber(project.amountIncVat),
        'Invoice Raised': project.invoiceRaised,
        'Invoice Date': project.dateForInvoice,
        'Paid On': project.paidOn,
        'Payment Status': project.paymentStatus,
        'Payment Notes': project.paymentNotes,
        'Cost': ensureNumber(project.cost),
        'PoC': project.poc,
        '# Tasks': project.tasks.length,
        'Task No': task.taskNo,
        'Subject': task.subject,
        'Country': task.country,
        'Task': task.task,
        'Order Accepted': task.orderAccepted,
        'Due Date': task.dueDate,
        'Task Status': task.taskStatus,
        'Completed On': task.completedOn,
        'Analyst 1': task.analyst1,
        'Analyst 2': task.analyst2,
        'Task Fees': ensureNumber(task.income),
        'Task VAT': ensureNumber(task.vat),
        'Task Amount Inc VAT': ensureNumber(task.amountIncVat)
      }));
    }
  });

  // Create worksheet and workbook
  const ws = XLSX.utils.json_to_sheet(excelData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Projects and Tasks");

  // Generate Excel file
  XLSX.writeFile(wb, "projects_and_tasks_export.xlsx");
};



  // Adjustable variables

  const columnWidth = '130px';  // Adjust as needed
  const rowHeight = '60px';     // Adjust as needed
  const fontSize = '18px';      // Adjust as needed
  const firstColumnPadding = '12px';  // New variable for left padding of the first column
  const countryColumnPadding = '20px';
  const subjectColumnPadding = '12px';


  const formatDate = (dateString) => {
    if (!dateString) {
      return '-';
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '-';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const dateformatter = (dateString, inputFormat = false) => {
    if (!dateString) {
      return inputFormat ? '' : '-';
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return inputFormat ? '' : '-';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    
    return inputFormat ? `${year}-${month}-${day}` : `${day}-${month}-${year}`;
  };

  const formatMoney = (value) => {
    if (typeof value !== 'number') {
      value = parseFloat(value);
    }
    if (isNaN(value)) {
      return '£0';
    }
    return `£${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  const formatMoneyWithoutPoundSign = (value) => {
    if (typeof value !== 'number') {
      value = parseFloat(value);
    }
    if (isNaN(value)) {
      return '£0';
    }
    return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };

  
  
  

  
  

  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowX: 'visible',
      p: 2,
      position: 'relative',
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 8, width: '300%' }}>
        <img src="/logo.png" alt="Logo" style={{ maxHeight: '150px' }} />
      </Box>
      <Box sx={{ position: 'relative', mb: 2, width: '100%', height: '80px' }}> {/* Adjust height as needed */}
  <Box sx={{ position: 'absolute', top: '38px', left: '10px' }}> {/* Adjust top and left as needed */}
    <TextField
      variant="outlined"
      placeholder="Search"
      value={search}
      onChange={handleSearchChange}
      sx={{
        width: '500px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '20px',
          height: '45px',
          padding: '0 15px', // Adjust padding to make the buttons wider
          fontSize: '20px', // Adjust font size to make the text bigger

        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={clearSearch}>
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </Box>
  <Box sx={{ position: 'absolute', top: '42px', right: '1165px', display: 'flex' }}>
  <IconButton onClick={() => setIsFilterDialogOpen(true)}>
    <FilterList />
  </IconButton>
  <IconButton onClick={() => setIsSortDialogOpen(true)}>
    <Sort />
  </IconButton>
</Box>





  <Box sx={{ position: 'absolute', top: '40px', right: '-200px', display: 'flex' }}> {/* Adjust top and right as needed */}
    {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
      <>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '20px',
            backgroundColor: '#3f51b5',
            textTransform: 'none',
            height: '40px',
            padding: '0 15px', // Adjust padding to make the buttons wider
            fontSize: '18px', // Adjust font size to make the text bigger
            mr: 2,
          }}
          onClick={handleOpenAdd}
        >
          Add Project
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '20px',
            backgroundColor: '#3f51b5',
            textTransform: 'none',
            height: '40px',
            padding: '0 15px', // Adjust padding to make the buttons wider
            fontSize: '18px', // Adjust font size to make the text bigger

            mr: 2,
          }}
          onClick={handleOpenAddClient}
        >
          Add Client
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '20px',
            backgroundColor: '#3f51b5',
            textTransform: 'none',
            height: '40px',
            padding: '0 15px', // Adjust padding to make the buttons wider
            fontSize: '18px', // Adjust font size to make the text bigger

            mr: 2,
          }}
          onClick={handleOpenEdit}
          disabled={selectedProjects.length !== 1}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '20px',
            backgroundColor: '#3f51b5',
            textTransform: 'none',
            height: '40px',
            padding: '0 15px', // Adjust padding to make the buttons wider
            fontSize: '18px', // Adjust font size to make the text bigger

            mr: 2,
          }}
          onClick={handleCreateInvoice}
          disabled={selectedProjects.length !== 1}
        >
          Create Invoice
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          sx={{
            borderRadius: '20px',
            backgroundColor: '#3f51b5',
            textTransform: 'none',
            height: '40px',
            padding: '0 15px', // Adjust padding to make the buttons wider
            fontSize: '18px', // Adjust font size to make the text bigger

          }}
          onClick={exportToExcel}
        >
          Export to Excel
        </Button>
      </>
    )}
  </Box>
  <Dialog open={isFilterDialogOpen} onClose={() => setIsFilterDialogOpen(false)}>
    <DialogTitle>Filter by Project Status</DialogTitle>
    <DialogContent>
      {Object.keys(selectedStatuses).map(status => (
        <FormControlLabel
          key={status}
          control={
            <Checkbox
              checked={selectedStatuses[status]}
              onChange={handleStatusChange}
              name={status}
            />
          }
          label={status}
        />
      ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setIsFilterDialogOpen(false)} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>


  <Dialog open={isSortDialogOpen} onClose={() => setIsSortDialogOpen(false)}>
  <DialogTitle>Sort Projects</DialogTitle>
  <DialogContent>
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel id="sort-column-label">Sort by</InputLabel>
      <Select
        labelId="sort-column-label"
        value={sortColumn}
        onChange={(e) => setSortColumn(e.target.value)}
        label="Sort by"
      >
        <MenuItem value="projectRef">Project Ref</MenuItem>
        <MenuItem value="projectManager">Project Manager</MenuItem>
        <MenuItem value="client">Client</MenuItem>
        <MenuItem value="tasks.dueDate">Task Due Date</MenuItem>
        <MenuItem value="amountIncVat">Fee Inc VAT</MenuItem>
      </Select>
    </FormControl>
    <FormControl fullWidth sx={{ mt: 2 }}>
      <InputLabel id="sort-order-label">Order</InputLabel>
      <Select
        labelId="sort-order-label"
        value={sortOrder}
        onChange={(e) => setSortOrder(e.target.value)}
        label="Order"
      >
        <MenuItem value="asc">Ascending</MenuItem>
        <MenuItem value="desc">Descending</MenuItem>
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsSortDialogOpen(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={() => {
      setIsSortDialogOpen(false);
      fetchProjects(); // Re-fetch projects with the new sorting parameters
    }} color="primary">
      Apply
    </Button>
  </DialogActions>
</Dialog>




  <Dialog open={isInvoiceDialogOpen} onClose={() => setIsInvoiceDialogOpen(false)}>
  <DialogTitle>Choose Invoice Option</DialogTitle>
  <DialogContent>
    <FormControlLabel
      control={
        <Radio
          checked={invoiceChoice === 'Show subject'}
          onChange={() => setInvoiceChoice('Show subject')}
          value="Show subject"
          name="invoice-choice"
        />
      }
      label="Show subject"
    />
    <FormControlLabel
      control={
        <Radio
          checked={invoiceChoice === 'Anonymise subject'}
          onChange={() => setInvoiceChoice('Anonymise subject')}
          value="Anonymise subject"
          name="invoice-choice"
        />
      }
      label="Anonymise subject"
    />
      <FormControl fullWidth sx={{ mt: 2 }}>
    <InputLabel id="company-label">Company</InputLabel>
    <Select
      labelId="company-label"
      value={selectedCompany}
      onChange={(e) => setSelectedCompany(e.target.value)}
      label="Company"
    >
      <MenuItem value="UK">UK Company</MenuItem>
      <MenuItem value="UAE">UAE Company</MenuItem>
    </Select>
  </FormControl>

  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsInvoiceDialogOpen(false)} color="primary">
      Cancel
    </Button>
    <Button onClick={handleInvoiceCreation} color="primary">
      Create Invoice
    </Button>
  </DialogActions>
</Dialog>


</Box>

      <Box sx={{
        width: 'max-content',
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}>
        <Typography 
          variant="h4" 
          sx={{ 
            position: 'absolute',
            top: '-58px',
            left: '10px',
            zIndex: 1,
          }}
        >
          Projects
        </Typography>
        <TableContainer component={Paper} elevation={0} sx={{ 
  border: '1px solid #e0e0e0', 
  width: 'max-content',
  maxWidth: 'none',
  overflowX: 'visible',
  height: '660px', // Set your desired height here
  overflowY: 'auto' // Enable vertical scrolling
}}>

          <Table stickyHeader sx={{ width: 'max-content' }}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" /> <TableCell />
                  <TableCell sx={{ width: '110px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '8px',textAlign: 'center' }}>Project Ref</TableCell>
                <TableCell sx={{ width: '80px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Proj Mgr</TableCell>
                <TableCell sx={{ width: '180px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Client</TableCell>

                <TableCell sx={{ width: '80px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Proj Status</TableCell>


                {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
                  <>
                    <TableCell sx={{ width: '70px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Fee</TableCell>
                    <TableCell sx={{ width: '60px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>VAT</TableCell>
                    <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '8px',paddingRight: '8px',textAlign: 'center' }}>Fee Inc VAT</TableCell>
                    <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Inv Raised</TableCell>
                    <TableCell sx={{ width: '110px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Inv Date</TableCell>
                    <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Paid On</TableCell>
                    <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Pay Status</TableCell>
            
                    <TableCell sx={{ width: '160px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Pay Notes</TableCell>
                    <TableCell sx={{ width: '70px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>Cost</TableCell>
                  </>
                )}
             <TableCell sx={{ width: '160px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: '0px',paddingRight: '0px',textAlign: 'center' }}>PoC</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project, index) => (
                <React.Fragment key={project.id}>
                  <TableRow
                   style={{
                    backgroundColor: index % 2 === 0 ? 'white' :'#f0f0f0' 
                  }}
                   /* style={{
                      backgroundColor: project.projectStatus === 'Overdue' ? 'lightcoral' :
                                       project.projectStatus === 'Attention' ? 'lightgoldenrodyellow' :
                                       project.projectStatus === 'In Progress' ? 'lightgreen' :
                                       project.projectStatus === 'Completed' ? 'lightblue' :
                                       project.projectStatus === 'To be invoiced' ? 'lightpink' :
                                       project.projectStatus === 'Invoiced' ? '#CBC3E3' :
                                       index % 2 === 0 ? 'white' : 'rgba(0, 0, 0, 0.04)'
                    }}*/
                  >
                    <TableCell sx={{ width: columnWidth/1.2, height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: firstColumnPadding,paddingRight: '8px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>
                      <Checkbox
                        checked={selectedProjects.includes(project.id)}
                        onChange={(event) => handleCheckboxChange(event, project.id)}
                      />
                    </TableCell>
                    <TableCell sx={{ width: columnWidth/1.2, height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: firstColumnPadding, paddingRight: '8px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', position: 'relative' }}>
  <div
    style={{
      position: 'absolute',
      top: '4px',
      left: '20px',
      backgroundColor: 'blue',
      color: 'white',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '12px',
      fontWeight: 'bold'
    }}
  >
  {project.tasks ? project.tasks.length : 0} 
  </div>
  <IconButton
    size="small"
    onClick={() => handleRowClick(project.id)}
    style={{ cursor: 'pointer' }}
  >
    {expandedRows[project.id] ? <ExpandLess /> : <ExpandMore />}
  </IconButton>
</TableCell>

                    <TableCell sx={{ width: '110px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '8px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>
                      <Typography
                        noWrap
                        component="span"
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleProjectRefClick(project.id);
                        }}
                        sx={{ color: 'blue', cursor: 'pointer', fontSize: fontSize }}
                      >
                        {project.projectRef}
                      </Typography>
                    </TableCell>



                    <TableCell sx={{ width: '80px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'projectManager')}> {editableCell && editableCell.projectId === project.id && editableCell.field === 'projectManager' ? ( <TextField value={project.projectManager} onChange={(e) => handleInputChange(e, project.id, 'projectManager')} onBlur={() => handleBlur(project.id, 'projectManager')} autoFocus /> ) : ( project.projectManager )} </TableCell>
                    
                    
                    
                    
                    <TableCell sx={{ width: '180px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>{project.client}</TableCell>
                    <TableCell
 sx={{ width: '120px', height: rowHeight, fontSize: '16px', padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', 
  backgroundColor: project.projectStatus === 'Overdue' ? 'lightcoral' :
                   project.projectStatus === 'Attention' ? '#f6d20e' :
                   project.projectStatus === 'In Progress' ? 'lightgreen' :
                   project.projectStatus === 'Completed' ? 'lightblue' :
                   project.projectStatus === 'To be invoiced' ? 'lightpink' :
                   project.projectStatus === 'Invoiced' ? '#CBC3E3' : 'inherit',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center'
}}

>
{project.projectStatus}</TableCell>

                    {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
                      <>
                         <TableCell sx={{ width: '70px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}> {formatMoney(project.income)}</TableCell>
                         <TableCell sx={{ width: '60px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}> {project.vat}%</TableCell>
                         <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '8px',paddingRight: '8px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}> {formatMoney(project.amountIncVat)}</TableCell>
                         
                         
                         <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'invoiceRaised')}> {editableCell && editableCell.projectId === project.id && editableCell.field === 'invoiceRaised' ? ( <TextField value={project.invoiceRaised} onChange={(e) => handleInputChange(e, project.id, 'invoiceRaised')} onBlur={() => handleBlur(project.id, 'invoiceRaised')} autoFocus /> ) : ( project.invoiceRaised )} </TableCell>


                         <TableCell sx={{ width: '80px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px', paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'dateForInvoice')}>{editableCell && editableCell.projectId === project.id && editableCell.field === 'dateForInvoice' ? (<TextField type="date" value={dateformatter(convertTimestampToDate(project.dateForInvoice), true)} onChange={(e) => handleInputChange(e, project.id, 'dateForInvoice')} onBlur={() => handleBlur(project.id, 'dateForInvoice')} InputLabelProps={{ shrink: true, }} autoFocus fullWidth /> ) : ( dateformatter(convertTimestampToDate(project.dateForInvoice)))} </TableCell>


                         <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px', paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'paidOn')}>{editableCell && editableCell.projectId === project.id && editableCell.field === 'paidOn' ? (<TextField type="date" value={dateformatter(convertTimestampToDate(project.paidOn), true)} onChange={(e) => handleInputChange(e, project.id, 'paidOn')} onBlur={() => handleBlur(project.id, 'paidOn')} InputLabelProps={{ shrink: true, }} autoFocus fullWidth /> ) : ( dateformatter(convertTimestampToDate(project.paidOn)))} </TableCell>


                         <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'paymentStatus')}> {editableCell && editableCell.projectId === project.id && editableCell.field === 'paymentStatus' ? ( <TextField value={project.paymentStatus} onChange={(e) => handleInputChange(e, project.id, 'paymentStatus')} onBlur={() => handleBlur(project.id, 'paymentStatus')} autoFocus /> ) : ( project.paymentStatus )} </TableCell>

                         <TableCell sx={{ width: '160px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'paymentNotes')}> {editableCell && editableCell.projectId === project.id && editableCell.field === 'paymentNotes' ? ( <TextField value={project.paymentNotes} onChange={(e) => handleInputChange(e, project.id, 'paymentNotes')} onBlur={() => handleBlur(project.id, 'paymentNotes')} autoFocus /> ) : ( project.paymentNotes )} </TableCell>


                         <TableCell sx={{ width: '70px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'cost')}> {editableCell && editableCell.projectId === project.id && editableCell.field === 'cost' ? ( <TextField value={project.cost} onChange={(e) => handleInputChange(e, project.id, 'cost')} onBlur={() => handleBlur(project.id, 'cost')} autoFocus /> ) : ( project.cost )} </TableCell>


                      </>
                    )}
                    
                    
                    
                    <TableCell sx={{ width: '160px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'poc')}> {editableCell && editableCell.projectId === project.id && editableCell.field === 'poc' ? ( <TextField value={project.poc} onChange={(e) => handleInputChange(e, project.id, 'poc')} onBlur={() => handleBlur(project.id, 'poc')} autoFocus /> ) : ( project.poc )} </TableCell>


                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={16}>
                      <Collapse in={expandedRows[project.id]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Typography variant="h6" gutterBottom component="div">
                            Tasks
                          </Typography>
                          <Table size="small" aria-label="tasks">
                            <TableHead>
                              <TableRow>
                              <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}>Task No</TableCell>
                                <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Subject</TableCell>
                                <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Country</TableCell>
                                <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Task</TableCell>
                                <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Order Accepted</TableCell>
                                <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Due Date</TableCell>
                                <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Task Status</TableCell>
                                <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Completed On</TableCell>
                                <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Analyst 1</TableCell>
                                <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Analyst 2</TableCell>
                                {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
                                  <>
                                    <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Fees</TableCell>
                                    <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> VAT</TableCell>
                                    <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> Amount Inc VAT</TableCell>
                                  </>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {project.tasks && project.tasks.length > 0 ? project.tasks.map((task, taskIndex) => (
                                <TableRow key={taskIndex} /*style={{
                                  backgroundColor: task.completedOn ? 'lightblue' :
                                                   task.taskStatus === 'Overdue' ? 'lightcoral' :
                                                   task.taskStatus === 'Attention' ? 'lightgoldenrodyellow' :
                                                   task.taskStatus === 'In Progress' ? 'lightgreen' :
                                                   taskIndex % 2 === 0 ? 'white' : 'rgba(0, 0, 0, 0.04)'
                                }}*/>
                                  <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> {task.taskNo}</TableCell>

                                  
                                  
                                  

                                  
                                  <TableCell sx={{ width: '150px', height: '40px', fontSize: '15px', padding: '0px', paddingLeft: '0px',paddingRight: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'subject', taskIndex)} > {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'subject' ? (<TextField value={task.subject} onChange={(e) => handleInputChange(e, project.id, 'subject', taskIndex)} onBlur={() => handleBlur(project.id, 'subject', taskIndex)} autoFocus />) : (task.subject)}</TableCell>
                                  
                                  
                                  
                                  
                                  
                                  
                                  
                                  
                                  
                                  <TableCell sx={{ fontSize: '15px', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'country', taskIndex)} > {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'country' ? (<TextField value={task.country} onChange={(e) => handleInputChange(e, project.id, 'country', taskIndex)} onBlur={() => handleBlur(project.id, 'country', taskIndex)} autoFocus/>) : (task.country)}</TableCell>
                                  <TableCell sx={{ fontSize: '15px', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'task', taskIndex)}> {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'task' ? (<Select value={task.task} onChange={(e) => handleInputChange(e, project.id, 'task', taskIndex)} onBlur={() => handleBlur(project.id, 'task', taskIndex)} autoFocus fullWidth>{taskOptions.map((option) => (<MenuItem key={option} value={option}>{option}</MenuItem>))}</Select>) : (task.task)}</TableCell>

                                  <TableCell sx={{ fontSize: '15px', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'orderAccepted', taskIndex)} > {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'orderAccepted' ? (<TextField type="date" value={dateformatter(convertTimestampToDate(task.orderAccepted), true)} onChange={(e) => handleInputChange(e, project.id, 'orderAccepted', taskIndex)} onBlur={() => handleBlur(project.id, 'orderAccepted', taskIndex)} autoFocus/>) : dateformatter(convertTimestampToDate((task.orderAccepted)))}</TableCell>
                                  <TableCell sx={{ fontSize: '15px', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'dueDate', taskIndex)} > {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'dueDate' ? (<TextField type="date" value={dateformatter(convertTimestampToDate(task.dueDate), true)} onChange={(e) => handleInputChange(e, project.id, 'dueDate', taskIndex)} onBlur={() => handleBlur(project.id, 'dueDate', taskIndex)} autoFocus/>) : dateformatter(convertTimestampToDate((task.dueDate)))}</TableCell>

                                  <TableCell
  style={{
    backgroundColor: task.completedOn ? 'lightblue' :
                     task.taskStatus === 'Overdue' ? 'lightcoral' :
                     task.taskStatus === 'Attention' ? '#f6d20e' :
                     task.taskStatus === 'In Progress' ? 'lightgreen' : 'inherit',
    color: 'white',
    fontWeight: '900',
    textAlign: 'center'
  }}
  
>
  {task.taskStatus}
</TableCell>
                                  
                                  <TableCell sx={{ fontSize: '15px', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'completedOn', taskIndex)} > {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'completedOn' ? (<TextField type="date" value={dateformatter(convertTimestampToDate(task.completedOn), true)} onChange={(e) => handleInputChange(e, project.id, 'completedOn', taskIndex)} onBlur={() => handleBlur(project.id, 'completedOn', taskIndex)} autoFocus/>) : dateformatter(convertTimestampToDate((task.completedOn)))}</TableCell>

                                  

                                  <TableCell sx={{ fontSize: '15px', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'analyst1', taskIndex)} > {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'analyst1' ? (<TextField value={task.analyst1} onChange={(e) => handleInputChange(e, project.id, 'analyst1', taskIndex)} onBlur={() => handleBlur(project.id, 'analyst1', taskIndex)} autoFocus/>) : (task.analyst1)}</TableCell>
                                  <TableCell sx={{ fontSize: '15px', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'analyst2', taskIndex)} > {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'analyst2' ? (<TextField value={task.analyst2} onChange={(e) => handleInputChange(e, project.id, 'analyst2', taskIndex)} onBlur={() => handleBlur(project.id, 'analyst2', taskIndex)} autoFocus/>) : (task.analyst2)}</TableCell>



                                  {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
                                    <>

                                      <TableCell sx={{ fontSize: '15px', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'income', taskIndex)} > {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'income' ? (<TextField value={task.income} onChange={(e) => handleInputChange(e, project.id, 'income', taskIndex)} onBlur={() => handleBlur(project.id, 'income', taskIndex)} autoFocus/>) : formatMoney(task.income)}</TableCell>


                                      <TableCell sx={{ fontSize: '15px', textAlign: 'center' }} onClick={() => handleCellClick(project.id, 'vat', taskIndex)} > {editableTaskCell && editableTaskCell.projectId === project.id && editableTaskCell.taskIndex === taskIndex && editableTaskCell.field === 'vat' ? (<TextField value={task.vat} onChange={(e) => handleInputChange(e, project.id, 'vat', taskIndex)} onBlur={() => handleBlur(project.id, 'vat', taskIndex)} autoFocus/>) : (task.vat)}%</TableCell>
                                      <TableCell sx={{ fontSize: '15px', textAlign: 'center' }}> {formatMoney(task.amountIncVat)}</TableCell>

                                    </>
                                  )}
                                </TableRow>
                              )): <TableRow><TableCell colSpan={10}>No tasks available</TableCell></TableRow>}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
  
      <Dialog open={openAdd} onClose={handleCloseAdd} maxWidth="md" fullWidth>
        <DialogTitle>Add Project</DialogTitle>
        <DialogContent>
          <AddProject onClose={handleCloseAdd} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAdd} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  
      <Dialog open={openAddClient} onClose={handleCloseAddClient} maxWidth="md" fullWidth>
        <DialogTitle>Add Client</DialogTitle>
        <DialogContent>
          <AddClient onClose={handleCloseAddClient} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddClient} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  
      <Dialog open={openEdit} onClose={handleCloseEdit} maxWidth="md" fullWidth>
        <DialogTitle>Edit Project</DialogTitle>
        <DialogContent>
          {selectedProjectId && <EditProject projectId={selectedProjectId} onClose={handleCloseEdit} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );};

export default Orders;
