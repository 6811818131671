import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  Box, Typography, Button, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Snackbar, TextField
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const modules = {
  toolbar: [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'align': [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['blockquote', 'code-block'],
    ['link', 'image'],
    ['clean']
  ],
};

const formats = [
  'font', 'header', 'align',
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'list', 'bullet',
  'blockquote', 'code-block',
  'link', 'image'
];

const ProjectDetail = ({ user }) => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [briefing, setBriefing] = useState('');
  const [open, setOpen] = useState(false);
  const [editingCompletedOn, setEditingCompletedOn] = useState({ taskNo: null, value: '' });

  // Adjustable variables
  const columnWidth = '130px';
  const rowHeight = '60px';
  const fontSize = '18px';
  const editorWidth = '1500px';  // New variable for editor width
  const firstColumnPadding = '12px';  // New variable for left padding of the first column
  const countryColumnPadding = '20px';
  const subjectColumnPadding = '12px';

  useEffect(() => {
    const fetchProject = async () => {
      const projectDoc = doc(db, 'projects', id);
      const projectSnapshot = await getDoc(projectDoc);
      if (projectSnapshot.exists()) {
        const projectData = projectSnapshot.data();
        setProject(projectData);
        setBriefing(projectData.projectBriefing);
      }
    };
    fetchProject();
  }, [id]);

  const handleBriefingChange = (value) => {
    setBriefing(value);
  };

  const handleSave = async () => {
    const projectDoc = doc(db, 'projects', id);
    await updateDoc(projectDoc, { projectBriefing: briefing });
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return '-';
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '-';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleEditCompletedOn = (task) => {
    setEditingCompletedOn({ taskNo: task.taskNo, value: task.completedOn });
  };

  const handleCompletedOnInputChange = (event) => {
    setEditingCompletedOn({ ...editingCompletedOn, value: event.target.value });
  };

  const fetchAndRefreshTask = async (taskNo) => {
    const projectDocRef = doc(db, 'projects', id);
    const projectDoc = await getDoc(projectDocRef);
  
    if (!projectDoc.exists()) {
      console.error('Project document does not exist');
      return;
    }
  
    const projectData = projectDoc.data();
    if (!projectData.tasks) {
      console.error('Project data does not contain tasks');
      return;
    }
  
    const now = new Date();
    const updatedTasks = projectData.tasks.map((task) => {
      if (task.taskNo !== taskNo) {
        return task;
      }
  
      let newTaskStatus = 'In Progress';
      const dueDate = new Date(task.dueDate);
      const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
  
      if (task.completedOn) {
        newTaskStatus = 'Completed';
      } else {
        if (dueDate < startOfToday) {
          newTaskStatus = 'Overdue';
        } else if (dueDate < endOfToday) {
          newTaskStatus = 'Attention';
        }
      }
  
      return { ...task, taskStatus: newTaskStatus };
    });
  
    await updateDoc(projectDocRef, { tasks: updatedTasks });
  
    // Update local state
    setProject({ ...project, tasks: updatedTasks });
  };

  const handleCompletedOnInputBlur = async (task) => {
    const updatedTasks = project.tasks.map((t) =>
      t.taskNo === task.taskNo ? { ...t, completedOn: editingCompletedOn.value } : t
    );
    const projectDocRef = doc(db, 'projects', id);
    await updateDoc(projectDocRef, { tasks: updatedTasks });
    setProject({ ...project, tasks: updatedTasks });
    setEditingCompletedOn({ taskNo: null, value: '' });

    // Refresh the status for the edited task
    fetchAndRefreshTask(task.taskNo);
  };

  if (!project) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowX: 'visible',
      p: 2,
      position: 'relative',  // Added to make it a positioning context
      mt: 10,  // Add top margin to make room for the absolutely positioned header
    }}>
      <Box sx={{
        position: 'absolute',
        top: -70,  // Adjust as needed
        left: -170,  // Adjust as needed
        zIndex: 1,
      }}>
        <Typography variant="h4">{project.projectRef}</Typography>
        <Typography variant="h6" color="textSecondary">{project.projectStatus}</Typography>
      </Box>
      <Paper elevation={3} sx={{ p: 2, mb: 3, width: editorWidth }}>
        <Typography variant="h6" gutterBottom>Project Briefing</Typography>
        <ReactQuill
          theme="snow"
          value={briefing}
          onChange={handleBriefingChange}
          modules={modules}
          formats={formats}
          style={{ height: '400px' }}
        />
        <Box mt={2} textAlign="right">
          <Button variant="contained" color="primary" onClick={handleSave}
            sx={{ 
              fontSize: '16px',  // Adjust this value to change the font size
              padding: '10px 20px',  // Optional: adjust padding to make the button larger overall
            }}
            >Save Changes</Button>
        </Box>
      </Paper>
      <Box sx={{
        width: 'max-content',
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        mt: 5,  // Add margin-top to make space for the "Tasks" title
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            position: 'absolute',
            top: -45,  // Adjust as needed
            left: 10,   // Adjust as needed
            zIndex: 1,
            fontSize: '20px',  // Adjust this value to change the font size
            fontWeight: 'bold',  // Optional: adjust if you want to change the font weight
          }}
        >
          Project Tasks
        </Typography>

        <TableContainer component={Paper} sx={{ 
          width: 'max-content',
          maxWidth: 'none',
          overflowX: 'visible',
        }}>
          <Table sx={{ width: 'max-content' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',paddingLeft: '8px',paddingRight: '0px',textAlign: 'center' }}>Task No</TableCell>
                <TableCell sx={{ width: '230px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',paddingLeft: subjectColumnPadding,textAlign: 'center' }}>Subject</TableCell>
                <TableCell sx={{ width: columnWidth/1.2, height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',paddingLeft: countryColumnPadding,textAlign: 'center' }}>Country</TableCell>
                <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',paddingLeft: countryColumnPadding, paddingRight: '0px',textAlign: 'center' }}>Task</TableCell>
                <TableCell sx={{ width: '140px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',paddingLeft: subjectColumnPadding, textAlign: 'center'}}>Accepted On</TableCell>
                <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',textAlign: 'center' }}>Due Date</TableCell>
                <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',textAlign: 'center' }}>Task Status</TableCell>
                <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',textAlign: 'center' }}>Analyst 1</TableCell>
                <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',textAlign: 'center' }}>Analyst 2</TableCell>
                {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
                  <>
                    <TableCell sx={{ width: '80px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',textAlign: 'center' }}>Fee</TableCell>
                    <TableCell sx={{ width: '60px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',textAlign: 'center' }}>VAT</TableCell>
                    <TableCell sx={{ width: '160px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',textAlign: 'center' }}>Fee Inc VAT</TableCell>
                  </>
                )}
                <TableCell sx={{ width: '140px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px',paddingRight: '8px',textAlign: 'center' }}>Completed On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {project.tasks && project.tasks.map((task, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px',paddingLeft: '8px', overflow: 'hidden', textOverflow: 'ellipsis',paddingRight: '0px',textAlign: 'center' }}>{task.taskNo}</TableCell>
                  <TableCell sx={{ width: '230px', height: rowHeight, fontSize: fontSize, padding: '0px',paddingLeft: subjectColumnPadding, overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>{task.subject}</TableCell>
                  <TableCell sx={{ width: columnWidth/1.2, height: rowHeight, fontSize: fontSize, padding: '0px',paddingLeft: countryColumnPadding, overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>{task.country}</TableCell>
                  <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: countryColumnPadding, overflow: 'hidden', textOverflow: 'ellipsis',paddingRight: '0px',textAlign: 'center' }}>{task.task}</TableCell>
                  <TableCell sx={{ width: '140px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis',paddingLeft: subjectColumnPadding,textAlign: 'center' }}>{formatDate(task.orderAccepted)}</TableCell>
                  <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>{formatDate(task.dueDate)}</TableCell>
                  <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', backgroundColor: task.completedOn ? 'lightblue' :
                     task.taskStatus === 'Overdue' ? 'lightcoral' :
                     task.taskStatus === 'Attention' ? '#f6d20e' :
                     task.taskStatus === 'In Progress' ? 'lightgreen' : 'inherit',
                    color: 'white',
                    textAlign: 'center' // Center align the text
                  }}>{task.taskStatus}</TableCell>
                  <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>{task.analyst1}</TableCell>
                  <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>{task.analyst2}</TableCell>
                  {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
                    <>
                      <TableCell sx={{ width: '80px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>{task.income}</TableCell>
                      <TableCell sx={{ width: '60px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>{task.vat}%</TableCell>
                      <TableCell sx={{ width: '160px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>{task.amountIncVat}</TableCell>
                    </>
                  )}
                  <TableCell sx={{ width: '140px', height: rowHeight, fontSize: fontSize, padding: '0px',paddingRight: '12px', overflow: 'hidden', textOverflow: 'ellipsis',textAlign: 'center' }}>
                    {editingCompletedOn.taskNo === task.taskNo ? (
                      <TextField
                        type="date"
                        value={editingCompletedOn.value}
                        onChange={handleCompletedOnInputChange}
                        onBlur={() => handleCompletedOnInputBlur(task)}
                        autoFocus
                        variant="standard"
                      />
                    ) : (
                      <Typography
                        component="span"
                        onClick={() => handleEditCompletedOn(task)}
                        sx={{ cursor: 'pointer' }}
                      >
                        {task.completedOn ? formatDate(task.completedOn) : '-'}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Changes saved successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProjectDetail;
