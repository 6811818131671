// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyAeExz-NQzpHmPNxHgWBDkjZWoiRxMhenE",
  authDomain: "fort-corps-test.firebaseapp.com",
  projectId: "fort-corps-test",
  storageBucket: "fort-corps-test.appspot.com",
  messagingSenderId: "986563539942",
  appId: "1:986563539942:web:9404e230a8ebd54eac1c27",
  measurementId: "G-S8PLNFJMC1"
};


const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };

