// src/components/EditProject.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { db } from '../firebaseConfig';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { collection, addDoc, getDocs, query, orderBy, limit, where } from 'firebase/firestore';

import { Autocomplete } from '@mui/material';


// Define the task, country, and analyst options directly
const taskOptions = [
  'DSS', 'PS', 'VS', 'IMEQ', 'BEQ', 'FL', 'SS', 'EQ', 'LC', 'MA', 'RPS', 'SV', 'VES'
];

const countryOptions = [
  'Algeria', 'Bahrain', 'Djibouti', 'Egypt', 'Iran', 'Iraq', 'Jordan', 'Kuwait', 
  'Lebanon', 'Libya', 'Mauritania', 'Morocco', 'Oman', 'Pakistan', 'Palestine', 
  'Qatar', 'Saudi Arabia', 'Somalia', 'Sudan', 'Syria', 'Tunisia', 'Turkey', 'UAE', 
  'UAE - Abu Dhabi', 'UAE - Ajman', 'UAE - Dubai', 'UAE - Fujairah', 'UAE - RAK', 
  'UAE - Sharjah', 'UAE - UAQ', 'Yemen'
];

const projectManagerOptions = [
  'Zak', 'Omar', 'Aseel', 'Muhab', 'Mustafa'
];


const EditProject = ({ projectId, onClose }) => {
  const [project, setProject] = useState({
    projectRef: '',
    projectBriefing: '',
    invoiceNo: '',
    projectStatus: '',
    projectManager: '',
    cost: '',
    client: '',
    poc: '',
    dateForInvoice: '',
    invoiceRaised: 'No',
    paymentStatus: '',
    paidOn: '',
    paymentNotes: '',
    tasks: [{ taskNo: '', subject: '', country: '', task: '', orderAccepted: '', dueDate: '', taskStatus: '', analyst1: '', analyst2: '', income: '', vat: '0', amountIncVat: '', completedOn: '' }]
  });


  const [clientOptions, setClientOptions] = useState([]);
  const [pocOptions, setPocOptions] = useState([]);




  useEffect(() => {
    const fetchProject = async () => {
      const docRef = doc(db, 'projects', projectId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const projectData = docSnap.data();
        setProject(projectData);

        // Fetch POCs for the current client
        if (projectData.client) {
          await fetchPocs(projectData.client);
        }
      }

      
    };

    const fetchClients = async () => {
      const clientsCollection = collection(db, 'clients');
      const snapshot = await getDocs(clientsCollection);
      const clients = snapshot.docs.map(doc => doc.data().clientName);
      setClientOptions(clients);
    };

    fetchProject();
    fetchClients();

  }, [projectId]);

  


  const fetchPocs = async (clientName) => {
    const clientQuery = query(
      collection(db, 'clients'), 
      where('clientName', '==', clientName)
    );
    const snapshot = await getDocs(clientQuery);
    if (!snapshot.empty) {
      const clientData = snapshot.docs[0].data();
      setPocOptions(clientData.pointOfContacts || []);
    } else {
      setPocOptions([]);
    }
  };

  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProject({ ...project, [name]: value });
  };

  const handleTaskChange = (index, e) => {
    const { name, value } = e.target;
    const newTasks = [...project.tasks];
    newTasks[index][name] = value;

    if (name === 'income' || name === 'vat') {
      const vatValue = name === 'vat' ? value : newTasks[index].vat;
      const incomeValue = name === 'income' ? value : newTasks[index].income;
      const amountIncVat = parseFloat(incomeValue) + parseFloat(incomeValue) * parseFloat(vatValue) / 100;
      newTasks[index].amountIncVat = amountIncVat.toFixed(2);
    }

    setProject((prevState) => ({ ...prevState, tasks: newTasks }));

    // Update main project income, VAT, and amountIncVat
    const updatedIncome = newTasks.reduce((acc, task) => acc + parseFloat(task.income || 0), 0);
    const updatedVat = newTasks.length > 0 ? newTasks[0].vat : '0';
    const updatedAmountIncVat = newTasks.reduce((acc, task) => acc + parseFloat(task.amountIncVat || 0), 0);

    setProject((prevState) => ({
      ...prevState,
      income: updatedIncome.toFixed(2),
      vat: updatedVat,
      amountIncVat: updatedAmountIncVat.toFixed(2)
    }));
  };

  const handleAddTask = () => {
    setProject({ ...project, tasks: [...project.tasks, { taskNo: '', subject: '', country: '', task: '', orderAccepted: '', dueDate: '', taskStatus: '', analyst1: '', analyst2: '', income: '', vat: '0', amountIncVat: '', completedOn: '' }] });
  };

  const handleRemoveTask = (index) => {
    const newTasks = project.tasks.filter((_, i) => i !== index);
    setProject({ ...project, tasks: newTasks });

    // Update main project income, VAT, and amountIncVat after task removal
    const updatedIncome = newTasks.reduce((acc, task) => acc + parseFloat(task.income || 0), 0);
    const updatedVat = newTasks.length > 0 ? newTasks[0].vat : '0';
    const updatedAmountIncVat = newTasks.reduce((acc, task) => acc + parseFloat(task.amountIncVat || 0), 0);

    setProject((prevState) => ({
      ...prevState,
      income: updatedIncome.toFixed(2),
      vat: updatedVat,
      amountIncVat: updatedAmountIncVat.toFixed(2)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const projectRef = doc(db, 'projects', projectId);
      await updateDoc(projectRef, {
        ...project,
        dateForInvoice: project.dateForInvoice || 'N/A',
        paidOn: project.paidOn || 'N/A'
      });
      onClose();
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h6">Edit Project</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Project Ref"
            name="projectRef"
            value={project.projectRef}
            onChange={handleInputChange}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Invoice No"
            name="invoiceNo"
            value={project.invoiceNo}
            onChange={handleInputChange}
            fullWidth
            //disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Project Briefing"
            name="projectBriefing"
            value={project.projectBriefing}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Project Status"
            name="projectStatus"
            value={project.projectStatus}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <Autocomplete
            options={projectManagerOptions}
            value={project.projectManager}
            onChange={(event, newValue) => setProject({ ...project, projectManager: newValue })}
            renderInput={(params) => <TextField {...params} label="Project Manager" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Cost"
            name="cost"
            value={project.cost}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            options={clientOptions}
            value={project.client}
            onChange={async (event, newValue) => {
              setProject({ ...project, client: newValue });
              await fetchPocs(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Client" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
        <Autocomplete
            options={pocOptions}
            value={project.poc}
            onChange={(event, newValue) => setProject({ ...project, poc: newValue })}
            renderInput={(params) => <TextField {...params} label="PoC" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Date for Invoice"
            name="dateForInvoice"
            value={project.dateForInvoice}
            onChange={handleInputChange}
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Invoice Raised</InputLabel>
            <Select
              name="invoiceRaised"
              value={project.invoiceRaised}
              onChange={handleInputChange}
              fullWidth
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Payment Status"
            name="paymentStatus"
            value={project.paymentStatus}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Paid on"
            name="paidOn"
            value={project.paidOn}
            onChange={handleInputChange}
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Payment Notes"
            name="paymentNotes"
            value={project.paymentNotes}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Tasks Required</Typography>
          {project.tasks.map((task, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Task {index + 1}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Task No"
                    name="taskNo"
                    value={task.taskNo}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Subject"
                    name="subject"
                    value={task.subject}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <Autocomplete
                  options={countryOptions}
                  value={task.country}
                  onChange={(event, newValue) => {
                    const newTasks = [...project.tasks];
                    newTasks[index].country = newValue;
                    setProject({ ...project, tasks: newTasks });
                  }}
                  renderInput={(params) => <TextField {...params} label="Country" fullWidth />}
                />

                </Grid>
                <Grid item xs={12} sm={4}>
                <Autocomplete
                  options={taskOptions}
                  value={task.task}
                  onChange={(event, newValue) => {
                    const newTasks = [...project.tasks];
                    newTasks[index].task = newValue;
                    setProject({ ...project, tasks: newTasks });
                  }}
                  renderInput={(params) => <TextField {...params} label="Task" fullWidth />}
                />

                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Order Accepted"
                    name="orderAccepted"
                    value={task.orderAccepted}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Due Date"
                    name="dueDate"
                    value={task.dueDate}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Task Status"
                    name="taskStatus"
                    value={task.taskStatus}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                <Autocomplete
                  options={projectManagerOptions}
                  value={task.analyst1}
                  onChange={(event, newValue) => {
                    const newTasks = [...project.tasks];
                    newTasks[index].analyst1 = newValue;
                    setProject({ ...project, tasks: newTasks });
                  }}
                  renderInput={(params) => <TextField {...params} label="Analyst 1" fullWidth />}
                />
                </Grid>
                <Grid item xs={12} sm={4}>
                <Autocomplete
                  options={projectManagerOptions}
                  value={task.analyst2}
                  onChange={(event, newValue) => {
                    const newTasks = [...project.tasks];
                    newTasks[index].analyst2 = newValue;
                    setProject({ ...project, tasks: newTasks });
                  }}
                  renderInput={(params) => <TextField {...params} label="Analyst 2" fullWidth />}
                />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Income"
                    name="income"
                    value={task.income}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>VAT</InputLabel>
                    <Select
                      name="vat"
                      value={task.vat}
                      onChange={(e) => handleTaskChange(index, e)}
                      fullWidth
                    >
                      <MenuItem value={0}>0%</MenuItem>
                      <MenuItem value={20}>20%</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Amount Inc VAT"
                    name="amountIncVat"
                    value={task.amountIncVat}
                    onChange={(e) => handleTaskChange(index, e)}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Completed On"
                        name="completedOn"
                        value={task.completedOn}
                        onChange={(e) => handleTaskChange(index, e)}
                        fullWidth
                        type="date"
                        InputLabelProps={{
                        shrink: true,
                         }}
                     />
                </Grid>

              </Grid>
              <Button variant="outlined" color="secondary" onClick={() => handleRemoveTask(index)} sx={{ mt: 2 }}>
                Remove Task
              </Button>
            </Box>
          ))}
          <Button variant="outlined" onClick={handleAddTask}>
            Add Task
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditProject;
