import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  IconButton,
  FormControlLabel
} from '@mui/material';
import { Sort, FilterList } from '@mui/icons-material';

import { getFirestore, collection, query, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Tasks = ({ user }) => {
  const getDefaultAnalyst = (email) => {
    switch (email) {
      case 'mustafa@fortcorps.com':
        return 'Mustafa';
      case 'aseel@fortcorps.com':
        return 'Aseel';
      case 'muhab@fortcorps.com':
        return 'Muhab';
      case 'omar@fortcorps.com':
        return 'Omar';
      case 'zak@fortcorps.com':
        return 'Zak';
    }
  };
  

  const [analyst, setAnalyst] = useState(getDefaultAnalyst(user.email));
  const [tasks, setTasks] = useState([]);


  const [editingCompletedOn, setEditingCompletedOn] = useState({ projectId: null, taskNo: null, value: '' });
  const [sortColumn, setSortColumn] = useState('projectRef');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedStatuses, setSelectedStatuses] = useState({
    'In Progress': true,
    'Attention': true,
    'Completed': true,
    'Overdue': true,
  });
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isSortDialogOpen, setIsSortDialogOpen] = useState(false);

  const taskOptions = ['DSS', 'PS', 'VS', 'IMEQ', 'BEQ', 'FL', 'SS', 'EQ', 'LC', 'MA', 'RPS', 'SV', 'VES'];

  const [selectedTasks, setSelectedTasks] = useState(() => {
    const initialTasks = {};
    taskOptions.forEach(task => {
      initialTasks[task] = true; // Set default to true or false as needed
    });
    return initialTasks;
  });
  

  const db = getFirestore();
  const navigate = useNavigate();

  

  const columnWidth = '130px';
  const rowHeight = '60px';
  const fontSize = '18px';
  const firstColumnPadding = '12px';
  const countryColumnPadding = '20px';
  const subjectColumnPadding = '12px';


  useEffect(() => {
    const savedStatuses = localStorage.getItem('taskSelectedStatuses');
    if (savedStatuses) {
      setSelectedStatuses(JSON.parse(savedStatuses));
    }
  
    const savedTasks = localStorage.getItem('taskSelectedTasks');
    if (savedTasks) {
      setSelectedTasks(JSON.parse(savedTasks));
    }
  }, []);
  

  useEffect(() => {
    const fetchTasks = async () => {
      const projectsQuery = query(collection(db, 'projects'));
      const querySnapshot = await getDocs(projectsQuery);
      let fetchedTasks = [];
      querySnapshot.forEach((doc) => {
        const projectData = doc.data();
        if (projectData.tasks) {
          projectData.tasks.forEach((task) => {
            if (analyst === 'All' || task.analyst1 === analyst || task.analyst2 === analyst) {
              fetchedTasks.push({ ...task, projectRef: projectData.projectRef, projectId: doc.id });
            }
          });
        }
      });
      
      // Filter by status
      fetchedTasks = fetchedTasks.filter(task => selectedStatuses[task.taskStatus]);

      // Filter by task type
      fetchedTasks = fetchedTasks.filter(task => selectedTasks[task.task]);

      // Sort tasks
      fetchedTasks.sort((a, b) => {
        let aValue = a[sortColumn];
        let bValue = b[sortColumn];
        if (sortColumn === 'projectRef') {
          const aParts = aValue.split('-').map(part => part.replace('P', ''));
          const bParts = bValue.split('-').map(part => part.replace('P', ''));
          const aYear = parseInt(aParts[1], 10);
          const bYear = parseInt(bParts[1], 10);
          const aNumber = parseInt(aParts[0], 10);
          const bNumber = parseInt(bParts[0], 10);
          return sortOrder === 'asc'
            ? aYear - bYear || aNumber - bNumber
            : bYear - aYear || bNumber - aNumber;
        }
        if (sortOrder === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    
      setTasks(fetchedTasks);
    };
    
    fetchTasks();
  }, [analyst, db, selectedStatuses, selectedTasks, sortColumn, sortOrder]);
  

  const handleAnalystChange = (event) => {
    setAnalyst(event.target.value);
  };

  const handleProjectRefClick = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  const handleFilterDialogOpen = () => {
    setIsFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setIsFilterDialogOpen(false);
  };

  const handleSortDialogOpen = () => {
    setIsSortDialogOpen(true);
  };

  const handleSortDialogClose = () => {
    setIsSortDialogOpen(false);
  };


  
  const handleStatusChange = (event) => {
    const updatedStatuses = {
      ...selectedStatuses,
      [event.target.name]: event.target.checked,
    };
    setSelectedStatuses(updatedStatuses);
    localStorage.setItem('taskSelectedStatuses', JSON.stringify(updatedStatuses)); // Save to local storage
  };
  

  const handleSelectAllTasks = () => {
    const allSelectedTasks = {};
    taskOptions.forEach(task => {
      allSelectedTasks[task] = true;
    });
    setSelectedTasks(allSelectedTasks);
    localStorage.setItem('taskSelectedTasks', JSON.stringify(allSelectedTasks));
  };
  
  const handleDeselectAllTasks = () => {
    const noneSelectedTasks = {};
    taskOptions.forEach(task => {
      noneSelectedTasks[task] = false;
    });
    setSelectedTasks(noneSelectedTasks);
    localStorage.setItem('taskSelectedTasks', JSON.stringify(noneSelectedTasks));
  };
  




  const fetchAndRefreshTask = async (projectId, taskNo) => {
    const projectDocRef = doc(db, 'projects', projectId);
    const projectDoc = await getDoc(projectDocRef);
  
    if (!projectDoc.exists()) {
      console.error('Project document does not exist');
      return;
    }
  
    const projectData = projectDoc.data();
    if (!projectData.tasks) {
      console.error('Project data does not contain tasks');
      return;
    }
  
    const now = new Date();
    const updatedTasks = projectData.tasks.map((task) => {
      if (task.taskNo !== taskNo) {
        return task;
      }
  
      let newTaskStatus = 'In Progress';
      const dueDate = new Date(task.dueDate);
      const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const endOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
  
      if (task.completedOn) {
        newTaskStatus = 'Completed';
      } else {
        if (dueDate < startOfToday) {
          newTaskStatus = 'Overdue';
        } else if (dueDate < endOfToday) {
          newTaskStatus = 'Attention';
        }
      }
  
      return { ...task, taskStatus: newTaskStatus };
    });
  
    await updateDoc(projectDocRef, { tasks: updatedTasks });
  
    // Update local state
    const updatedTask = updatedTasks.find(task => task.taskNo === taskNo);
    const updatedTaskIndex = tasks.findIndex(task => task.projectId === projectId && task.taskNo === taskNo);
    const updatedLocalTasks = [...tasks];
    updatedLocalTasks[updatedTaskIndex] = { ...updatedLocalTasks[updatedTaskIndex], taskStatus: updatedTask.taskStatus };
  
    setTasks(updatedLocalTasks);
  };
  
  









  const handleEditCompletedOn = (task) => {
    setEditingCompletedOn({ projectId: task.projectId, taskNo: task.taskNo, value: task.completedOn });
  };
  
  const handleCompletedOnInputChange = (event) => {
    setEditingCompletedOn({ ...editingCompletedOn, value: event.target.value });
  };
  
  const handleCompletedOnInputBlur = async () => {
    const taskIndex = tasks.findIndex(task => task.projectId === editingCompletedOn.projectId && task.taskNo === editingCompletedOn.taskNo);
  
    if (taskIndex === -1) {
      return; // If the task is not found, do nothing
    }
  
    const updatedTasks = [...tasks];
    updatedTasks[taskIndex].completedOn = editingCompletedOn.value;
    setTasks(updatedTasks);
  
    const projectDocRef = doc(db, 'projects', editingCompletedOn.projectId);
    const projectDoc = await getDoc(projectDocRef);
  
    if (!projectDoc.exists()) {
      console.error('Project document does not exist');
      return;
    }
  
    const projectData = projectDoc.data();
  
    // Ensure tasks property exists
    if (!projectData.tasks) {
      console.error('Project data does not contain tasks');
      return;
    }
  
    // Update the tasks array in Firestore
    const updatedTasksArray = projectData.tasks.map((task) =>
      task.taskNo === editingCompletedOn.taskNo ? { ...task, completedOn: editingCompletedOn.value } : task
    );
  
    await updateDoc(projectDocRef, {
      tasks: updatedTasksArray.filter(task => task !== undefined)
    });
  
    setEditingCompletedOn({ projectId: null, taskNo: null, value: '' });

  // Refresh the status for only the edited task
  fetchAndRefreshTask(editingCompletedOn.projectId, editingCompletedOn.taskNo);

  };

  

  const handleTaskChange = (event) => {
    const updatedTasks = {
      ...selectedTasks,
      [event.target.name]: event.target.checked,
    };
    setSelectedTasks(updatedTasks);
    localStorage.setItem('taskSelectedTasks', JSON.stringify(updatedTasks)); // Save to local storage
  };
  






  


  const formatDate = (dateString) => {
    if (!dateString) {
      return '-';
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '-';
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowX: 'visible',
      p: 2,
      position: 'relative',
    }}>
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: -230,
        zIndex: 1,
      }}>
        <Typography variant="h4" sx={{ mb: 2 }}>Tasks</Typography>
 
        <FormControl sx={{ mb: 2, minWidth: 300 }}>
  <InputLabel id="analyst-select-label">Analyst</InputLabel>
  <Select
    labelId="analyst-select-label"
    id="analyst-select"
    value={analyst}
    label="Analyst"
    onChange={handleAnalystChange}
    sx={{ width: '100%' }}
  >
    {(user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
      <MenuItem value="All">All</MenuItem>
    )}
    <MenuItem value="Zak" disabled={['mustafa@fortcorps.com', 'aseel@fortcorps.com', 'muhab@fortcorps.com'].includes(user.email)}>Zak</MenuItem>
    <MenuItem value="Omar" disabled={['mustafa@fortcorps.com', 'aseel@fortcorps.com', 'muhab@fortcorps.com'].includes(user.email)}>Omar</MenuItem>
    <MenuItem value="Muhab" disabled={['mustafa@fortcorps.com', 'aseel@fortcorps.com'].includes(user.email)}>Muhab</MenuItem>
    <MenuItem value="Aseel" disabled={['mustafa@fortcorps.com', 'muhab@fortcorps.com'].includes(user.email)}>Aseel</MenuItem>
    <MenuItem value="Mustafa" disabled={['aseel@fortcorps.com', 'muhab@fortcorps.com'].includes(user.email)}>Mustafa</MenuItem>
  </Select>
</FormControl>

      </Box>

      <Box sx={{ position: 'absolute', top: 70, right: 980 }}>
        <IconButton onClick={handleFilterDialogOpen}>
          <FilterList />
        </IconButton>
        <IconButton onClick={handleSortDialogOpen}>
          <Sort />
        </IconButton>
      </Box>

      <Dialog open={isFilterDialogOpen} onClose={handleFilterDialogClose}>
        <DialogTitle>Filter Tasks</DialogTitle>
        <DialogContent>
        <Typography variant="h6">Filter by Task Status</Typography>
          {Object.keys(selectedStatuses).map(status => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={selectedStatuses[status]}
                  onChange={handleStatusChange}
                  name={status}
                />
              }
              label={status}
            />
          ))}

           <Typography variant="h6" sx={{ mt: 2 }}>Filter by Task Type</Typography>
             {/* Add Select All and Deselect All buttons */}
             <Box sx={{ display: 'flex', mb: 1 }}>
    <Button onClick={handleSelectAllTasks} color="primary" size="small">Select All</Button>
    <Button onClick={handleDeselectAllTasks} color="primary" size="small" sx={{ ml: 1 }}>Deselect All</Button>
  </Box>

  {taskOptions.map(taskOption => (
    <FormControlLabel
      key={taskOption}
      control={
        <Checkbox
          checked={selectedTasks[taskOption]}
          onChange={handleTaskChange}
          name={taskOption}
        />
      }
      label={taskOption}
    />
  ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFilterDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isSortDialogOpen} onClose={handleSortDialogClose}>
        <DialogTitle>Sort Tasks</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="sort-column-label">Sort by</InputLabel>
            <Select
              labelId="sort-column-label"
              value={sortColumn}
              onChange={(e) => setSortColumn(e.target.value)}
              label="Sort by"
            >
              <MenuItem value="projectRef">Project Ref</MenuItem>
              <MenuItem value="dueDate">Task Due Date</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="sort-order-label">Order</InputLabel>
            <Select
              labelId="sort-order-label"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              label="Order"
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSortDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSortDialogClose} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>


      <Box sx={{
        width: 'max-content',
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        mt: 15,
      }}>
        <TableContainer component={Paper} sx={{
          width: 'max-content',
          maxWidth: 'none',
          overflowX: 'visible',
          border: '1px solid #e0e0e0',
        }}>
          <Table sx={{ width: 'max-content' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: columnWidth / 1.2, height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: firstColumnPadding, paddingRight: '8px', textAlign: 'center' }}>Project Ref</TableCell>
                <TableCell sx={{ width: columnWidth / 3, height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: subjectColumnPadding, paddingRight: '0px', textAlign: 'center' }}>Task No</TableCell>
                <TableCell sx={{ width: '230px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: subjectColumnPadding, textAlign: 'center' }}>Subject</TableCell>
                <TableCell sx={{ width: columnWidth / 1.2, height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: countryColumnPadding, textAlign: 'center' }}>Country</TableCell>
                <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: countryColumnPadding, paddingRight: '0px', textAlign: 'center' }}>Task</TableCell>
                <TableCell sx={{ width: '140px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingLeft: subjectColumnPadding, textAlign: 'center' }}>Accepted On</TableCell>
                <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', textAlign: 'center' }}>Due Date</TableCell>
                <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', textAlign: 'center' }}>Task Status</TableCell>
                <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', textAlign: 'center' }}>Analyst 1</TableCell>
                <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', textAlign: 'center' }}>Analyst 2</TableCell>
                {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
                  <>
                    <TableCell sx={{ width: '80px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', textAlign: 'center' }}>Fee</TableCell>
                    <TableCell sx={{ width: '60px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', textAlign: 'center' }}>VAT</TableCell>
                    <TableCell sx={{ width: '160px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', textAlign: 'center' }}>Fee Inc VAT</TableCell>
                  </>
                )}
                <TableCell sx={{ width: '140px', height: rowHeight, fontSize: fontSize, fontWeight: 'bold', padding: '0px', paddingRight: '8px', textAlign: 'center' }}>Completed On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task, index) => (
                <TableRow key={index}>
                 
                 

                 <TableCell sx={{ width: columnWidth / 1.2, height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: firstColumnPadding, paddingRight: '8px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>
  <a
    href={`/project/${task.projectId}`}
    onClick={(e) => {
      e.preventDefault(); // Prevent the default anchor behavior
      if (e.button === 0) { // Left-click
        navigate(`/project/${task.projectId}`);
      }
    }}
    style={{ color: 'blue', cursor: 'pointer', fontSize: fontSize, textDecoration: 'none' }}
  >
    {task.projectRef}
  </a>
</TableCell>


                  <TableCell sx={{ width: columnWidth / 3, height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: subjectColumnPadding, overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '0px', textAlign: 'center' }}>{task.taskNo}</TableCell>
                  <TableCell sx={{ width: '230px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: subjectColumnPadding, overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{task.subject}</TableCell>
                  <TableCell sx={{ width: columnWidth / 1.2, height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: countryColumnPadding, overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{task.country}</TableCell>
                  <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingLeft: countryColumnPadding, overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '0px', textAlign: 'center' }}>{task.task}</TableCell>
                  <TableCell sx={{ width: '140px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: subjectColumnPadding, textAlign: 'center' }}>{formatDate(task.orderAccepted)}</TableCell>
                  <TableCell sx={{ width: '120px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{formatDate(task.dueDate)}</TableCell>
                  <TableCell sx={{
                    width: '120px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', backgroundColor: task.completedOn ? 'lightblue' :
                      task.taskStatus === 'Overdue' ? 'lightcoral' :
                        task.taskStatus === 'Attention' ? '#f6d20e' :
                          task.taskStatus === 'In Progress' ? 'lightgreen' : 'inherit',
                    color: 'white',
                    textAlign: 'center'
                  }}>{task.taskStatus}</TableCell>

                


                  <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{task.analyst1}</TableCell>
                  <TableCell sx={{ width: '100px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{task.analyst2}</TableCell>
                  {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com') && (
                    <>
                      <TableCell sx={{ width: '80px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{task.income}</TableCell>
                      <TableCell sx={{ width: '60px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{task.vat}%</TableCell>
                      <TableCell sx={{ width: '160px', height: rowHeight, fontSize: fontSize, padding: '0px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{task.amountIncVat}</TableCell>
                    </>
                  )}





    <TableCell sx={{ width: '140px', height: rowHeight, fontSize: fontSize, padding: '0px', paddingRight: '12px', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>
      {editingCompletedOn.projectId === task.projectId && editingCompletedOn.taskNo === task.taskNo ? (
        <TextField
          type="date"
          value={editingCompletedOn.value}
          onChange={handleCompletedOnInputChange}
          onBlur={handleCompletedOnInputBlur}
          autoFocus
          variant="standard"
        />
      ) : (
        <Typography
          component="span"
          onClick={() => handleEditCompletedOn(task)}
          sx={{ cursor: 'pointer' }}
        >
          {task.completedOn ? formatDate(task.completedOn) : '-'}
        </Typography>
      )}
    </TableCell>
                
                
                
                
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Tasks;
